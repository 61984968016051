import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import { themedColor } from "../../../utils/configs";
import StrategyBuilder from "./StrategyBuilder";
import OIVisualizer from "./OIVisualizer";

const DerivativesPages = () => {
  const tabsConfig = {
    strategyBuilder: {
      name: "Strategy Builder",
      component: <StrategyBuilder />,
    },
    strategyBuilder2: {
      name: "Analysis",
      component: <OIVisualizer />,
    },
  };

  const [selectedTab, setSelectedTab] = useState("strategyBuilder");

  return (
    <div
      className="row m-0 p-0"
      style={{
        position: "relative",
      }}
    >
      <Nav
        tabs
        style={{
          color: "var(--text-color) !important",
        }}
        className="mb-3"
      >
        {Object.keys(tabsConfig).map((key, index) => (
          <NavItem
            key={index}
            onClick={() => {
              setSelectedTab(key);
            }}
            className="p-2"
            style={{
              cursor: "pointer",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              ...(selectedTab === key
                ? {
                    backgroundColor: "var(--text-color)",
                  }
                : {}),
            }}
          >
            <NavLink
              active={selectedTab === key ? true : false}
              style={{
                color:
                  selectedTab === key ? themedColor.light : "var(--text-color)",
                textDecoration: "none",
              }}
            >
              {tabsConfig[key].name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <div className="px-1 text-start left-border-div-sec">
        {tabsConfig[selectedTab].component}
      </div>
    </div>
  );
};

export default DerivativesPages;
