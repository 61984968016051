import React, { useEffect, useState } from "react";
import derivativesStore, { setSBOptionLegs } from "../derivatives-store";
import { chartColor, isMobile } from "../../../../utils/configs";

const OptionLeg = ({
  strikePrice,
  lastPrice,
  optionType,
  action,
  timeAdded,
  deleteIndex,
  expiryDate,
  lots,
  pChange,
  change,
  lotSize,
}) => {
  const [updatedStore, setUpdatedStore] = useState(derivativesStore.getState());
  const [isExpanded, setIsExpanded] = useState(false);
  const borderStyle = "1px solid var(--text-color) !important";
  useEffect(() => {
    const unsubscribe = derivativesStore.subscribe(() => {
      setUpdatedStore(derivativesStore.getState());
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const handleOptionLegUpdate = (sp, ot, ac, l) => {
    const newLeg = {
      ...updatedStore.optionLegs[deleteIndex],
      ...updatedStore.currData.optionChain.find(
        (ocs) => ocs.optionType == ot && ocs.strikePrice == sp
      ),
      action: ac,
      lots: l,
    };

    derivativesStore.dispatch(
      setSBOptionLegs({
        type: "replace",
        optionLegIndex: deleteIndex,
        optionLeg: newLeg,
      })
    );
  };

  const DeleteBTN = (
    <div className="col-2 text-center px-1 mt-2">
      <button
        type="button"
        className="btn btn-sm btn-danger ms-1"
        onClick={() => {
          derivativesStore.dispatch(
            setSBOptionLegs({
              type: "delete",
              optionLegIndex: deleteIndex,
            })
          );
        }}
      >
        <i className="fas fa-trash-can" />
      </button>
    </div>
  );

  return (
    <form className="mt-2 border rounded ">
      <div
        className="row text-xs rounded p-0 m-0 py-2 c-pointer "
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
        style={{
          backgroundColor:
            action == "S" ? chartColor.opacRedMore : chartColor.opacGreenMore,
          fontWeight: "bolder",
        }}
      >
        <div className="col-7 px-2">
          <span
            className={`div-pill bg-${
              action == "S" ? "danger" : "success"
            } px-2 py-1 rounded`}
            style={{
              fontWeight: 400,
              color: "#fff",
            }}
          >
            {action}
          </span>
          <span className="ms-2">
            {expiryDate} {strikePrice} {optionType}
          </span>
        </div>
        <div className="col-5 text-end">
          <span className={`text-${pChange < 0 ? "danger" : "success"}`}>
            {lastPrice} ({pChange}%)
          </span>
        </div>
      </div>
      {isExpanded ? (
        <div className="row mx-2 mt-2 pb-2">
          <div className="col-md-3 col-6 vert-center px-1 mt-2">
            <div className="input-group">
              <input
                type="number"
                className="qfInputType pl-3 form-control"
                style={{
                  borderLeft: borderStyle,
                  borderTop: borderStyle,
                  borderBottom: borderStyle,
                  maxHeight: "30px",
                }}
                placeholder="Lots"
                aria-label="Lots"
                aria-describedby={`lots_${deleteIndex}`}
                value={lots}
                min={1}
                onChange={(e) => {
                  handleOptionLegUpdate(
                    strikePrice,
                    optionType,
                    action,
                    e.target.value
                  );
                }}
              />
              <div className="input-group-append ">
                <span
                  className="input-group-text qfInputType-addon-input-label p-2"
                  id={`lots_${deleteIndex}`}
                  style={{
                    maxHeight: "30px",
                    borderTopLeftRadius: "unset !important",
                    borderBottomLeftRadius: "unset !important",
                  }}
                >
                  x{lotSize}
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-6 vert-center px-1 mt-2">
            <select
              onChange={(e) => {
                handleOptionLegUpdate(e.target.value, optionType, action, lots);
              }}
              value={strikePrice}
              className="qfInputType px-2 "
              style={{
                height: "30px",
                border: borderStyle,
              }}
            >
              {(updatedStore.currData?.optionChain || [{ strikePrice }]).map(
                (ocs, index) => {
                  return (
                    <option value={ocs.strikePrice} key={index}>
                      {ocs.strikePrice}
                    </option>
                  );
                }
              )}
            </select>
          </div>
          <div className="col-md-2 col-5 vert-center px-1 mt-2">
            <select
              onChange={(e) => {
                handleOptionLegUpdate(
                  strikePrice,
                  e.target.value,
                  action,
                  lots
                );
              }}
              value={optionType}
              className="qfInputType px-2"
              style={{
                height: "30px",
                border: borderStyle,
              }}
            >
              <option value="PE">PE</option>
              <option value="CE">CE</option>
            </select>
          </div>
          {isMobile() ? DeleteBTN : <></>}
          <div className="col-md-2 col-5 vert-center px-1 mt-2">
            <select
              onChange={(e) => {
                handleOptionLegUpdate(
                  strikePrice,
                  optionType,
                  e.target.value,
                  lots
                );
              }}
              value={action}
              className="qfInputType px-2"
              style={{
                height: "30px",
                border: borderStyle,
              }}
            >
              <option value="B">B</option>
              <option value="S">S</option>
            </select>
          </div>
          {isMobile() ? <></> : DeleteBTN}
        </div>
      ) : (
        <></>
      )}
    </form>
  );
};

export default OptionLeg;
