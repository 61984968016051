import React, { useEffect, useState } from "react";
import LivePrice from "../../views/preLogin/components/LivePrice";
import Navbar from "../Navbar";
import { useNavigate } from "react-router-dom";
import { commonApiGet } from "../../utils/api";
import { notificationTopup } from "../../utils/NotificationTopup";
import { isLoggedin } from "../../utils/configs";

const CommonCardViewer = ({
  shortComponent = true,
  isProtected = false,
  navigateIfProtected = `/signin?callback=${location.pathname}`,
  pathForRef = "Not Provided",
  children,
}) => {
  const [isUserLoggedin, setIsUserLoggedin] = useState(!isProtected);
  const n = useNavigate();
  useEffect(() => {
    if (isProtected) {
      if (!isLoggedin()) {
        n(navigateIfProtected);
      } else {
        (async () => {
          await commonApiGet(
            "/user/getuser",
            `COMMON_CARD_V_${location.pathname}`,
            false
          )
            .then((res) => {
              setIsUserLoggedin(true);
            })
            .catch((err) => {
              notificationTopup(err.message);
              setIsUserLoggedin(false);
              n(navigateIfProtected);
            });
        })();
      }
    }
  }, [pathForRef]);

  return (
    <>
      {isUserLoggedin && (
        <div className="container-fluid">
          <Navbar />
          <LivePrice />
          <div className="main-content">
            <center>
              <div className="row">
                <div
                  className="futures-card m-0 col-12"
                  style={{
                    overflowY: "scroll",
                    maxHeight: "85vh",
                    overflowX: "hidden",
                  }}
                >
                  <div
                    className="row g-3"
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    {shortComponent ? (
                      <div className="col-md-4">{children}</div>
                    ) : (
                      <div className="col-12">{children}</div>
                    )}
                  </div>
                </div>
              </div>
            </center>
          </div>
        </div>
      )}
    </>
  );
};

export default CommonCardViewer;
