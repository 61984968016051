import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { commonApiGet, commonApiPost, getCookie } from "../../../utils/api";
import { notificationTopup } from "../../../utils/NotificationTopup";
import QFLoader from "../../../components/vision/QFLoader";

export const loginUrl = "https://api.quibblefrost.in/auth/login";

const Ctas = ({ lgSm = "lg" }) => {
  const n = useNavigate();

  const [authStatus, setAuthStatus] = useState(null);
  const loginCallBackUrl = "dashboard";

  const getLogin = async () => {
    await commonApiPost("/auth/login", {})
      .then((res) => {
        // openAuthPopup(res.data.url)
        location.href = res.data.url;
      })
      .catch((err) => {
        notificationTopup(err.message);
      });
  };
  const getReg = async () => {
    await commonApiPost("/auth/signup", {})
      .then((res) => {
        // openAuthPopup(res.data.url);
        location.href = res.data.url;
      })
      .catch((err) => {
        notificationTopup(err.message);
      });
  };

  const openAuthPopup = (url) => {
    const authUrl = url; // Replace with your actual auth URL
    const width = 500;
    const height = 850;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    const popup = window.open(
      authUrl,
      "_blank",
      `width=${width},height=${height},top=${top},left=${left}`
    );

    window.addEventListener("message", (event) => {
      console.log(event.data);
      if (event.data === "close_popup") {
        popup.close();
        window.focus();
        location.reload();
      }
    });

    if (popup) {
      const popupInterval = setInterval(() => {
        try {
          console.log("hello", popup.location.href);
          if (popup.location.href.includes(loginCallBackUrl)) {
            // const queryParams = new URL(popup.location.href).searchParams;
            // const token = queryParams.get("token"); // Extract token or relevant data
            // setAuthStatus(token);
            popup.close();
            clearInterval(popupInterval);
          }
        } catch (error) {
          // Cross-origin errors can be ignored, they are expected until we reach the same-origin callback URL
        }
        if (popup.closed) {
          clearInterval(popupInterval);
        }
      }, 500);
    }
  };

  return (
    <div
      className=""
      style={{
        color: "var(--text-color) !important",
      }}
    >
      {getCookie("access_token") === "" ? (
        <>
          <button
            type="button"
            className={`btn-${lgSm} rounded-pill px-4 general-nmphc-des our-border btn-hover-effect m-1`}
            style={{
              fontWeight: 400,
              color: "var(--text-color)",
            }}
            onClick={() => {
              // getLogin();
              n("/signin");
            }}
          >
            Signin
            {/* <i className="fa-solid fa-right-to-bracket"></i> */}
          </button>
          <button
            type="button"
            className={`btn-${lgSm} rounded-pill px-4 general-nmphc-des our-border btn-hover-effect m-1`}
            style={{
              fontWeight: 400,
              color: "var(--text-color)",
            }}
            onClick={() => {
              // getReg();
              n("/signup");
            }}
          >
            Signup
            {/* <i className="fa-solid fa-file-signature"></i> */}
          </button>
        </>
      ) : (
        <Link to="/dashboard">
          <button
            type="button"
            className={`btn-${lgSm} rounded-pill px-4 general-nmphc-des our-border btn-hover-effect`}
            style={{
              fontWeight: 400,
              color: "var(--text-color)",
            }}
          >
            Explore
          </button>
        </Link>
      )}
    </div>
  );
};

export default Ctas;

export const getGoogleLogin = async (loaderShow) => {
  loaderShow(true);
  await commonApiPost("/auth/google", {})
    .then((res) => {
      // openAuthPopup(res.data.url)
      location.href = res.data.url;
    })
    .catch((err) => {
      notificationTopup(err.message);
      loaderShow(false);
    });
};

export const GoogBtn = ({ text = "Signin" }) => {
  const [loaderShow, setLoaderShow] = useState(false);
  return (
    <div className="social-signup">
      <span
        className="p-1 c-pointer rounded bx-nmprc-out"
        style={{ backgroundColor: "var(--button-bg)" }}
        onClick={() => {
          if (!loaderShow) getGoogleLogin(setLoaderShow);
        }}
      >
        {loaderShow ? (
          <QFLoader />
        ) : (
          <>
            <i
              className=" p-1 fab fa-google rounded"
              style={{ backgroundColor: "var(--body-bg)", fontSize: "12px" }}
            />
            <span
              style={{
                fontSize: "14px",
                fontWeight: 900,
                color: "var(--body-bg)",
              }}
            >
              {" "}
              | {text} with Google
            </span>
          </>
        )}
      </span>
    </div>
  );
};

export const LoginBtn = () => {
  return (
    <>
      <Link to="/signin" style={{
         fontSize: '14px'
      }}>Signin</Link>
    </>
  );
};
