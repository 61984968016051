import React, { useEffect, useState } from "react";
import derivativesStore from "./derivatives-store";
import PayoffChart from "./chart/PayoffChart";

const StrategyBuilder = () => {
  const [updatedStore, setUpdatedStore] = useState(derivativesStore.getState());

  useEffect(() => {
    const unsubscribe = derivativesStore.subscribe(() => {
      setUpdatedStore(derivativesStore.getState());
    });
    return () => {
      unsubscribe();
    };
  }, [updatedStore]);

  return (
    <div
      style={{
        maxHeight: "69vh",
        overflowY: "scroll",
      }}
    >
      <PayoffChart />
    </div>
  );
};

export default StrategyBuilder;
