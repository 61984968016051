import React, { useEffect } from "react";
import { HomePage } from "../views/HomePage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import "../static/css/index.css";
import Signup from "./authentications/Signup";
import { NSE } from "./NSE";
import {
  hawkReport,
  hawkReportTypeIdentifiers,
} from "../utils/hawkeye/hawkHelper";
import { Home } from "./preLogin/Home";
import { getCookie } from "../utils/api";
import PrivacyPolicy from "./preLogin/components/PrivacyPolicy";
import Tnc from "./preLogin/components/Tnc";
import EquityPage from "./equity/EquityPage";
import Signin from "./authentications/Signin";
import IpoData from "../components/NSEComponents/IpoData";
import CommonCardViewer from "../components/vision/CommonCardViewer";
import CryptoRedirector from "./CryptoRedirector";
import { Holiday } from "../components/NSEComponents/Holiday";
import IntradayStocks from "../components/NSEComponents/IntradayStocks";
import MyWatchlists from "../components/features/myWatchlists/MyWatchlists";
import SharedWatchlist from "../components/features/myWatchlists/SharedWatchlist";
import Derivatives from "../components/features/derivatives/Derivatives";
import Scanners from "../components/NSEComponents/Scanners";

export default function AppRoutes() {
  const extraRoutes = [
    {
      path: "/ipo-details",
      component: <IpoData />,
      shortComponent: true,
    },
    {
      path: "/market-holiday",
      component: <Holiday />,
      shortComponent: true,
    },
    {
      path: "/intraday-stocks",
      component: <IntradayStocks />,
      shortComponent: true,
    },
    {
      path: "/fundamental-scanners",
      component: <Scanners />,
      shortComponent: true,
      isProtected: true,
    },
    {
      path: "/my-watchlists",
      component: <MyWatchlists />,
      shortComponent: false,
      isProtected: true,
    },
    {
      path: "/watchlist/:watchlistId",
      component: <SharedWatchlist />,
      shortComponent: false,
    },
    {
      path: "/index/derivatives/options",
      component: <Derivatives />,
      shortComponent: false,
      // isProtected: true,
    },
  ];
  const apiCallOnLoad = async () => {
    try {
      hawkReport(
        new Error("HAWK REPORTING"),
        {},
        hawkReportTypeIdentifiers.initReport
      );
    } catch (error) {
      console.error("QF-JOURNAL-HAWKERRORSENDINGINIT", error);
    }
  };

  const apiCallOnUnload = async () => {
    try {
      hawkReport(
        new Error("HAWK REPORTING"),
        {},
        hawkReportTypeIdentifiers.sessionEndReport
      );
    } catch (error) {
      console.error("QF-JOURNAL-HAWKERRORSENDINGEND", error);
    }
  };

  useEffect(() => {
    // API call on window load
    apiCallOnLoad();

    // API call before window unload
    window.addEventListener("beforeunload", apiCallOnUnload);

    return () => {
      // Remove event listener on component unmount
      window.removeEventListener("beforeunload", apiCallOnUnload);
    };
  }, []);

  const theme = useSelector((state) => state.theme);
  document.documentElement.setAttribute("data-theme", theme || "light");

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={getCookie("access_token") === "" ? <Home /> : <NSE />}
          exact
        />
        <Route path="/crypto" element={<HomePage />} exact />
        <Route path="/crypto-redirector" element={<CryptoRedirector />} exact />
        <Route path="/dashboard" element={<NSE />} exact />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} exact />
        <Route path="/terms-and-conditions" element={<Tnc />} exact />
        <Route path="/info/:exchange/:symbol" element={<EquityPage />} />
        <Route path="/index/:exchange/:symbol" element={<EquityPage />} />
        <Route path="/signup" element={<Signup />} exact />
        <Route path="/signin" element={<Signin />} exact />

        {extraRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <CommonCardViewer
                shortComponent={route.shortComponent}
                isProtected={route.isProtected || false}
                pathForRef={route.path}
              >
                {route.component}
              </CommonCardViewer>
            }
            exact
          />
        ))}
        <Route path="*" element={<h1>WOW</h1>} />
      </Routes>
    </BrowserRouter>
  );
}
