import React, { useEffect, useState, useRef } from "react";
import { isMarketOpen, webSockQF } from "../../../utils/configs";
import { notificationTopup } from "../../../utils/NotificationTopup";
import { commonApiGet } from "../../../utils/api";

const LivePrice = () => {
  const [prices, setPrices] = useState([]);
  const [websoc, setWebsoc] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const tickerRef = useRef(null);
  const contentRef = useRef(null);
  const [tickerItems, setTickerItems] = useState([]);
  const [position, setPosition] = useState(0);

  const [isOneTimeAPICalled, setIsOneTimeAPICalled] = useState(false);

  const isOneTimeAPICalledRef = useRef(isOneTimeAPICalled); // Create a ref

  // Update the ref whenever isOneTimeAPICalled changes
  useEffect(() => {
    isOneTimeAPICalledRef.current = isOneTimeAPICalled;
  }, [isOneTimeAPICalled]);

  const fetchLivePrices = async () => {
    const marketOpen = await isMarketOpen();

    if (!marketOpen && isOneTimeAPICalledRef.current) return;

    const url = "/websock/ltp/HOME";

    await commonApiGet(url, "LIVE_PRICE_API", false)
      .then((res) => {
        setPrices(res.data?.data || []);
      })
      .catch((error) => {
        notificationTopup(error.message);
      });

    setIsOneTimeAPICalled(true);
  };

  useEffect(() => {
    fetchLivePrices(); // Initial fetch
    const interval = setInterval(fetchLivePrices, 15000); // Refresh every 15 seconds

    return () => {
      clearInterval(interval); // Clean up the interval on unmount
    };
  }, []); // Add dependencies as needed

  useEffect(() => {
    const interval = setInterval(() => {
      setTickerItems(prices);
    }, 1000); // Update every 5 seconds

    return () => clearInterval(interval);
  }, [prices]);

  useEffect(() => {
    const ticker = tickerRef.current;
    const content = contentRef.current;

    if (ticker && content && tickerItems.length > 0) {
      const tickerWidth = ticker.offsetWidth;
      const contentWidth = content.offsetWidth;

      let animationFrameId;

      const animate = () => {
        if (!isPaused) {
          setPosition((prevPosition) => {
            let newPosition = prevPosition - 1;
            if (newPosition <= -contentWidth / 2) {
              newPosition = 0;
            }
            return newPosition;
          });
        }
        animationFrameId = requestAnimationFrame(animate);
      };

      animationFrameId = requestAnimationFrame(animate);

      return () => {
        cancelAnimationFrame(animationFrameId);
      };
    }
  }, [tickerItems, isPaused]);

  const handleTouchStart = () => {
    setIsPaused(true);
  };

  const handleTouchEnd = () => {
    setIsPaused(false);
  };

  const renderTickerItems = () => {
    return (
      Array.isArray(tickerItems) &&
      tickerItems.map((value, index) => (
        <span
          key={index}
          className={`ticker-item px-2 mx-1 my-3 general-nmphc-desV2`}
          style={{
            display: "inline-block",
            fontSize: "14px",
          }}
        >
          <strong>
            <i
              className={
                value.percentChange < 0
                  ? "fas fa-arrow-down text-danger"
                  : value.percentChange > 0
                  ? "fas fa-arrow-up text-success"
                  : ""
              }
            ></i>{" "}
            {value.tradingSymbol}
          </strong>{" "}
          | {`${value.ltp} (${value.percentChange}%)`}
        </span>
      ))
    );
  };

  return (
    <div
      ref={tickerRef}
      className="ticker-container"
      style={{ overflow: "hidden", width: "100%" }}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <div
        ref={contentRef}
        className="ticker-content"
        style={{
          display: "inline-block",
          whiteSpace: "nowrap",
          transform: `translateX(${position}px)`,
        }}
      >
        {renderTickerItems()}
        {renderTickerItems()}
      </div>
    </div>
  );
};

export default LivePrice;
