import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react"; // Import ECharts component
import derivativesStore, { setSBOptionLegs } from "../derivatives-store";
import OptionLeg from "./OptionLeg";
import { calcPayoff } from "./utils";
import { convertDaysToYears } from "./utils";
import { getDaysToExpiry } from "./utils";
import {
  chartColor,
  findIndexAll,
  themedColor,
} from "../../../../utils/configs";
import { useSelector } from "react-redux";
import { calculateMaxPain, calculatePOP } from "../calculationHelpers";

const PayoffChart = () => {
  const theme = useSelector((store) => store.theme);
  const [updatedStore, setUpdatedStore] = useState(derivativesStore.getState());
  const [targetDate, setTargetDate] = useState(new Date());
  const [underlyingValue, setUnderlyingValue] = useState(
    updatedStore.currData?.underlying?.underlyingValue || 0
  );
  const [lotSize, setLotSize] = useState(
    updatedStore.currData?.underlying?.lotsize || 0
  );

  useEffect(() => {
    const unsubscribe = derivativesStore.subscribe(() => {
      setUpdatedStore(derivativesStore.getState());
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (updatedStore.currData && updatedStore.currData.underlying) {
      setUnderlyingValue(updatedStore.currData.underlying.underlyingValue);
      setLotSize(updatedStore.currData.underlying.lotsize);

      updatedStore.optionLegs.forEach((val, ind) => {
        console.log(val);
        const updatedOptionLeg = {
          ...val,
          ...updatedStore.currData.optionChain.find(
            (ocs) =>
              ocs.optionType === val.optionType &&
              ocs.strikePrice === val.strikePrice &&
              ocs.expiryDate === val.expiryDate
          ),
        };

        derivativesStore.dispatch(
          setSBOptionLegs({
            type: "replace",
            optionLegIndex: ind,
            optionLeg: updatedOptionLeg,
          })
        );
      });
    }
  }, [updatedStore.currData]);

  const strikePriceRange = parseInt(underlyingValue * 0.025) || 1000;
  const strikePriceInterval = 0.05;
  const baseStrikePrice =
    Math.floor(underlyingValue / strikePriceInterval) * strikePriceInterval -
    strikePriceRange;

  const calculatePayoff = () => {
    const strikePrices = Array.from(
      { length: (strikePriceRange / strikePriceInterval) * 2 },
      (_, i) => baseStrikePrice + i * strikePriceInterval
    );

    const totalPayoffs = strikePrices.map(() => 0);
    const targetDatePayoffs = strikePrices.map(() => 0);

    updatedStore.optionLegs.forEach((leg) => {
      const {
        strikePrice,
        optionType,
        lastPrice,
        action,
        expiryDate,
        impliedVolatility,
        theta,
        lots,
      } = leg;

      const expiry = new Date(expiryDate);
      const timeToExpiry = convertDaysToYears(
        getDaysToExpiry(expiry, targetDate)
      );

      const legPayoffs = strikePrices.map((strike) => {
        let intrinsicValue = 0;

        if (optionType === "CE") {
          intrinsicValue = Math.max(strike - strikePrice, 0); // Call intrinsic value
        } else if (optionType === "PE") {
          intrinsicValue = Math.max(strikePrice - strike, 0); // Put intrinsic value
        }

        return action === "B"
          ? (intrinsicValue - lastPrice) * lotSize * lots
          : (lastPrice - intrinsicValue) * lotSize * lots;
      });

      const legPayoffsTarget = strikePrices.map((strike) => {
        return calcPayoff(
          optionType === "CE" ? "c" : "p", // Convert option type to 'c' or 'p'
          strike, // Use strike price as the futures price for payoff calculation
          strikePrice,
          timeToExpiry,
          impliedVolatility / 100,
          lastPrice,
          lots, // Assuming 1 lot for simplicity
          lotSize, // Assuming lot size of 1 for simplicity
          action
        );
      });

      const legTargetDatePayoffs = legPayoffsTarget.map((payoff) => {
        // Calculate time decay
        const totalTimeDecay = theta * timeToExpiry;

        // Adjust the payoff for target date
        return payoff - totalTimeDecay; // Adjust based on time decay
      });

      legPayoffs.forEach((payoff, index) => {
        totalPayoffs[index] += payoff;
      });
      legPayoffsTarget.forEach((payoff, index) => {
        targetDatePayoffs[index] += legTargetDatePayoffs[index];
      });
    });

    return {
      strikePrices,
      totalPayoffs: totalPayoffs.map((val) => val.toFixed(2)),
      targetDatePayoffs: targetDatePayoffs.map((val) => val.toFixed(2)),
    };
  };

  const { strikePrices, totalPayoffs, targetDatePayoffs } = calculatePayoff();
  const breakEvens = findIndexAll(totalPayoffs, (x) => x == 0);
  const { POP } = calculatePOP(strikePrices, totalPayoffs);

  const positivePayoffs = totalPayoffs.map((payoff) =>
    payoff >= 0 ? payoff : null
  );
  const negativePayoffs = totalPayoffs.map((payoff) =>
    payoff < 0 ? payoff : null
  );

  const nearestStrikeBelow =
    Math.floor(underlyingValue / strikePriceInterval) * strikePriceInterval;
  const lineDecAbove =
    strikePrices.indexOf(nearestStrikeBelow) +
    (underlyingValue - nearestStrikeBelow) / strikePriceInterval;

  // console.log(updatedStore);

  // Extract unique strike prices
  const ocSp = [
    ...new Set(
      updatedStore.currData?.optionChain?.map((val) => val.strikePrice)
    ),
  ];

  // Initialize objects to store aggregated open interest
  const callOIMap = {};
  const putOIMap = {};

  // Iterate over the optionChain to aggregate open interest
  updatedStore.currData?.optionChain?.forEach((val) => {
    if (val.optionType === "CE") {
      callOIMap[val.strikePrice] =
        (callOIMap[val.strikePrice] || 0) + val.openInterest;
    } else if (val.optionType === "PE") {
      putOIMap[val.strikePrice] =
        (putOIMap[val.strikePrice] || 0) + val.openInterest;
    }
  });

  // Create arrays for call and put open interest based on unique strike prices
  const ocCOI = ocSp.map((strike) => callOIMap[strike] || 0);
  const ocPOI = ocSp.map((strike) => putOIMap[strike] || 0);

  // Calculate max pain
  const maxPain = calculateMaxPain(ocSp, ocCOI, ocPOI);
  const maxPainInd = strikePrices.indexOf(maxPain);

  const chartOptions = {
    tooltip: {
      trigger: "axis",
      className: "customEchartohlcTooltip glassmorfy-it general-nmphc-des",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
      formatter: (params) => {
        // Filter out series with undefined or null values
        const filteredParams = params.filter(
          (val) => val.value !== undefined && val.value !== null
        );

        // If there are no valid params, return a message indicating no data
        if (filteredParams.length === 0) {
          return "No data available";
        }

        // Generate tooltip content
        let tooltipContent = `<strong>Strike Price: ${parseFloat(
          params[0].axisValue
        ).toFixed(2)}</strong><br/>`;

        filteredParams.forEach((param) => {
          const seriesName = param.seriesName;
          const value = param.value;
          const color = param.color || "black"; // Default to black if no color is provided
          // Check if the series has a positive or negative value for proper display
          tooltipContent += `
            <span style="color:${color} !important;">●</span> ${seriesName}: 
            <strong>₹ ${
              value !== null && value !== undefined ? value : "N/A"
            }</strong><br/>
          `;
        });
        tooltipContent += `
          <span style="color:${chartColor.yellow} !important;">●</span> Max Pain: 
          <strong>${maxPain}</strong><br/>
        `;
        tooltipContent += `
        <br/>
        <strong>Probability of Profit: ${POP.toFixed(2)}%</strong>
      `;
        return tooltipContent;
      },
    },
    legend: {
      data: ["Current Payoff", "Target Date Payoff", "Max Pain"],
      top: "2%",
      left: "center",
      textStyle: {
        fontSize: 14,
        color: themedColor[theme],
      },
    },
    grid: {
      left: "5%",
      right: "5%",
      bottom: "10%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: strikePrices,

      name: "",
      nameTextStyle: {
        fontWeight: "bold",
        fontSize: 12,
      },
      axisLabel: {
        formatter: (value) => parseFloat(value).toFixed(2),
        color: themedColor[theme],
        fontSize: 12,
      },

      splitLine: { show: false },
    },
    yAxis: {
      type: "value",
      name: "",
      nameTextStyle: {
        fontWeight: "bold",
        fontSize: 12,
      },
      axisLabel: {
        formatter: (value) => `${value} ₹`,
        color: themedColor[theme],
        fontSize: 12,
      },

      splitLine: { show: false },
    },
    series: [
      {
        showSymbol: false, // Removes dots from the line
        name: "Current Payoff",
        type: "line",
        data: positivePayoffs,
        itemStyle: {
          color: "green",
        },
        lineStyle: {
          width: 2,
        },
        areaStyle: {
          color: "rgba(0, 255, 0, 0.2)",
        },
        smooth: true,
        markLine: {
          symbol: "none",
          data: [
            {
              name: "Underlying Price",
              xAxis: lineDecAbove,
              lineStyle: {
                type: "dashed",
                color: themedColor[theme],
                width: 2,
              },
              label: {
                formatter: () =>
                  `${updatedStore.selectedIndex}: ${underlyingValue}`,
                color: themedColor[theme],
              },
            },
            {
              name: "",
              yAxis: 0,
              lineStyle: {
                type: "solid",
                color: chartColor.green,
                width: 2,
              },
              label: {
                formatter: () => ``,
                show: false,
              },
            },
          ],
        },
      },
      {
        name: "Max Pain", // Dummy series for the markLine
        type: "line",
        data: [], // No data, as this is just for the legend
        lineStyle: {
          type: "dashed",
          color: chartColor.yellow,
          width: 2,
        },
        markLine: {
          symbol: "none",
          data: [
            {
              xAxis: maxPainInd,
              name: "Max Pain",

              lineStyle: {
                type: "dashed",
                color: chartColor.yellow,
                width: 2,
              },
              label: {
                formatter: () => `Max Pain: ${maxPain}`,
                color: themedColor[theme],
                show: false,
              },
            },
          ],
        },
      },
      {
        showSymbol: false, // Removes dots from the line
        name: "Current Payoff",
        type: "line",
        data: negativePayoffs,
        itemStyle: {
          color: "red",
        },
        lineStyle: {
          width: 2,
        },
        areaStyle: {
          color: "rgba(255, 0, 0, 0.2)",
        },
        smooth: true,
      },
      {
        showSymbol: false, // Removes dots from the line

        name: "Target Date Payoff",
        type: "line",
        data: targetDatePayoffs,
        itemStyle: {
          color: "#0dcaf0",
        },
        lineStyle: {
          width: 2,
          type: "solid",
        },
        smooth: true,
      },
    ],
  };

  const formatDateToLocalString = (date) => {
    const localDate = new Date(date);
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(localDate.getDate()).padStart(2, "0");
    const hours = String(localDate.getHours()).padStart(2, "0");
    const minutes = String(localDate.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const getMinDate = () => {
    const minDate = new Date();
    minDate.setDate(minDate.getDate() - 1); // Set min to -1 day
    return minDate.toISOString().slice(0, 16);
  };

  const getMaxDate = () => {
    const maxDate = new Date(updatedStore.selectedExpiry);
    maxDate.setDate(maxDate.getDate() + 1); // Set max to +1 day
    return maxDate.toISOString().slice(0, 16);
  };

  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6; // 0 = Sunday, 6 = Saturday
  };

  return (
    <div className="py-3">
      <h3>{updatedStore.selectedIndex} Options Payoff Chart</h3>
      <br />
      <br />

      {updatedStore.optionLegs.length > 0 ? (
        <>
          <ReactECharts
            option={{ ...chartOptions }}
            style={{ height: "420px", width: "100%" }}
          />
          <div className="row pt-2 px-2">
            <div className="col-sm-6">
              <table className="">
                <tbody>
                  {breakEvens.map((points, index) => (
                    <tr key={index}>
                      <td className="px-2">Breakeven {index + 1}:</td>
                      <td>{parseFloat(strikePrices[points]).toFixed(2)}</td>
                    </tr>
                  ))}
                  <tr>
                    <td className="px-2">Max Pain:</td>
                    <td>{maxPain}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-sm-3 col-4 vert-center text-end">
              <span>Target Date:</span>
            </div>
            <div className="col-sm-3 col-8 vert-center">
              <input
                type="datetime-local"
                className="qfInputType p-2 mt-2 ms-2"
                value={formatDateToLocalString(targetDate)}
                // onChange={(e) => setTargetDate(new Date(e.target.value))}
                onChange={(e) => {
                  const selectedDate = new Date(e.target.value);
                  if (!isWeekend(selectedDate)) {
                    setTargetDate(selectedDate);
                  }
                }}
                min={getMinDate()}
                max={getMaxDate()}
                onInvalid={(e) => {
                  if (isWeekend(new Date(e.target.value))) {
                    e.target.setCustomValidity("Please select a weekday.");
                  } else {
                    e.target.setCustomValidity("");
                  }
                }}
              />
            </div>
          </div>
          <hr />
          <div className="row pt-2 px-3">
            {updatedStore.optionLegs.map((leg, index) => (
              <div key={index} className="col-md-6 ">
                <OptionLeg {...leg} deleteIndex={index} lotSize={lotSize} />
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="text-center pt-2">Add Trade to see payoff chart</div>
      )}
    </div>
  );
};

export default PayoffChart;
