import React, { useEffect, useMemo, useRef, useState } from "react";
import { chartColor, decideColor, isBetween } from "../../../utils/configs";
import { groupINRCurrencyNSE } from "../../../utils/functions";
import derivativesStore, { addOptionLeg } from "./derivatives-store";
import GetOpenPositionFor from "./chart/GetOpenPositionFor";

const OptionChainViewer = ({
  optionChain,
  topThrees,
  underlying,
  underlyingValue,
  strikePrices,
  toScrollSetMiddleHR,
}) => {
  const { data, Exp } = optionChain;
  const calculateNearestStrikePrice = useMemo(() => {
    if (!optionChain.data || !underlyingValue) return null;
    return strikePrices.reduce((prev, curr) =>
      Math.abs(curr - underlyingValue) < Math.abs(prev - underlyingValue)
        ? curr
        : prev
    );
  }, [strikePrices, underlyingValue]);

  const [isInitialScrollDone, setIsInitialScrollDone] = useState(false);

  const [selectedOption, setSelectedOption] = useState([undefined, undefined]);
  const [selectedOptionLots, setSelectedOptionLots] = useState(1);

  const borderStyle = "1px solid var(--text-color) !important";

  // Create refs for the table containers and debounce timeout
  const table1ContainerRef = useRef(null);
  const table2ContainerRef = useRef(null);
  const table3ContainerRef = useRef(null);
  const debounceTimeoutRef = useRef(null); // Ref for debounce timeout

  // Function to sync the scroll of other tables with the source table
  const syncScrollHr = (source, target) => {
    if (source === table1ContainerRef.current) {
      target.scrollLeft =
        source.scrollWidth - source.scrollLeft - target.offsetWidth;
    } else if (source === table3ContainerRef.current) {
      target.scrollLeft =
        source.scrollWidth - source.scrollLeft - target.offsetWidth;
    }
  };

  const syncScrollVr = (source, target) => {
    target.scrollTop = source.scrollTop;
  };

  useEffect(() => {
    if (isInitialScrollDone) {
      const table1Container = table1ContainerRef.current;
      const table2Container = table2ContainerRef.current;
      const table3Container = table3ContainerRef.current;

      // Event listener for scroll on table1
      const handleScrollTable1 = () => {
        // Clear previous timeout
        if (debounceTimeoutRef.current) {
          clearTimeout(debounceTimeoutRef.current);
        }

        // Set a new timeout for debouncing
        debounceTimeoutRef.current = setTimeout(() => {
          syncScrollHr(table1Container, table3Container);
          syncScrollVr(table1Container, table2Container);
          syncScrollVr(table1Container, table3Container);
        }, 1); // Adjust debounce time as necessary
      };

      // Event listener for scroll on table2
      const handleScrollTable2 = () => {
        if (debounceTimeoutRef.current) {
          clearTimeout(debounceTimeoutRef.current);
        }

        debounceTimeoutRef.current = setTimeout(() => {
          syncScrollVr(table2Container, table1Container);
          syncScrollVr(table2Container, table3Container);
        }, 1);
      };

      // Event listener for scroll on table3
      const handleScrollTable3 = () => {
        if (debounceTimeoutRef.current) {
          clearTimeout(debounceTimeoutRef.current);
        }

        debounceTimeoutRef.current = setTimeout(() => {
          syncScrollHr(table3Container, table1Container);
          syncScrollVr(table3Container, table1Container);
          syncScrollVr(table3Container, table2Container);
        }, 1);
      };

      // Attach event listeners
      table1Container.addEventListener("scroll", handleScrollTable1);
      table2Container.addEventListener("scroll", handleScrollTable2);
      table3Container.addEventListener("scroll", handleScrollTable3);

      // Clean up event listeners on unmount
      return () => {
        table1Container.removeEventListener("scroll", handleScrollTable1);
        table2Container.removeEventListener("scroll", handleScrollTable2);
        table3Container.removeEventListener("scroll", handleScrollTable3);
        // Clear the timeout on unmount
        clearTimeout(debounceTimeoutRef.current);
      };
    }
  }, [isInitialScrollDone]);

  // Initial scroll state
  useEffect(() => {
    const table1Container = table1ContainerRef.current;
    const table2Container = table2ContainerRef.current;
    const table3Container = table3ContainerRef.current;
    if (table1Container) {
      const index = strikePrices.indexOf(calculateNearestStrikePrice);
      let scrollToVal =
        (index + 1.5) * 50 + 25 - table1Container.clientHeight / 2;
      table1Container.scrollLeft = table1Container.offsetWidth;
      table1Container.scrollTop = scrollToVal;
      table2Container.scrollTop = scrollToVal;
      table3Container.scrollTop = scrollToVal;
      table3Container.scrollLeft = 0;
      setIsInitialScrollDone(true);
    }
  }, [toScrollSetMiddleHR]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
        justifyContent: "space-between",
        position: "relative",
      }}
      className="p-0 m-0"
    >
      {/* 
        
        CALL

      */}
      <div
        className="tabular-container"
        id="tableCall-container"
        ref={table1ContainerRef}
      >
        <table id="tableCall" className=" zoo-optionChn">
          <thead>
            <tr>
              <td>VEGA</td>
              <td>THETA</td>
              <td>GAMMA</td>
              <td>DELTA</td>
              <td>VOL</td>
              <td>OI</td>
              <td>AVG</td>
              <td>LTP</td>
            </tr>
          </thead>
          <tbody>
            {Object.keys(data).map((val, index) => {
              const strikePrice = parseFloat(val);
              const underlyingValue =
                data[Object.keys(data)[0]]?.CE?.underlyingValue;
              const isCallITM = strikePrice < underlyingValue;
              const callBgColor = isCallITM
                ? chartColor.opacGreenMore
                : chartColor.opacRedMore;
              const nxtStrkPrc =
                data[Object.keys(data)[index + 1]]?.CE?.strikePrice;
              let toShowUV = isBetween(
                parseFloat(underlyingValue),
                strikePrice,
                parseFloat(nxtStrkPrc)
              );
              return (
                <React.Fragment key={`QF_OC_INDEX_KEY_CALL_${index}`}>
                  <tr
                    className="oc-bs-wrapper"
                    onClick={() => {
                      setSelectedOption([val, "CE"]);
                    }}
                  >
                    <td
                      style={{
                        backgroundColor: callBgColor,
                      }}
                    >
                      {data[val]?.CE?.vega?.toFixed(4) || "-"}
                    </td>
                    <td
                      style={{
                        backgroundColor: callBgColor,
                      }}
                    >
                      {data[val]?.CE?.theta?.toFixed(4) || "-"}
                    </td>
                    <td
                      style={{
                        backgroundColor: callBgColor,
                      }}
                    >
                      {data[val]?.CE?.gamma?.toFixed(4) || "-"}
                    </td>
                    <td
                      style={{
                        backgroundColor: callBgColor,
                      }}
                    >
                      {data[val]?.CE?.delta?.toFixed(4) || "-"}
                    </td>
                    <td
                      style={{
                        backgroundColor:
                          strikePrice > underlyingValue
                            ? decideColor(
                                strikePrice,
                                topThrees?.call?.byVolume || [],
                                callBgColor
                              )
                            : callBgColor,
                        textAlign: "left",
                      }}
                    >
                      {groupINRCurrencyNSE(data[val]?.CE?.totalTradedVolume) ||
                        "-"}
                      <br />
                      <span>
                        IV:
                        {data[val]?.CE?.impliedVolatility?.toFixed(2)}
                      </span>
                    </td>
                    <td
                      style={{
                        backgroundColor:
                          strikePrice > underlyingValue
                            ? decideColor(
                                strikePrice,
                                topThrees?.call?.byOI || [],
                                callBgColor
                              )
                            : callBgColor,
                        textAlign: "left",
                      }}
                    >
                      {groupINRCurrencyNSE(data[val]?.CE?.openInterest) || "-"}
                      <br />
                      <span>
                        {groupINRCurrencyNSE(
                          data[val]?.CE?.changeinOpenInterest
                        )}
                      </span>
                    </td>

                    <td
                      style={{
                        backgroundColor: callBgColor,
                      }}
                    >
                      {groupINRCurrencyNSE(data[val]?.CE?.avgPrice, true) ||
                        "-"}
                    </td>
                    <td
                      style={{
                        backgroundColor: callBgColor,
                        textAlign: "left",
                      }}
                    >
                      {groupINRCurrencyNSE(data[val]?.CE?.lastPrice, true) ||
                        "-"}
                      <br />
                      <span>
                        {groupINRCurrencyNSE(data[val]?.CE?.pChange)}%
                      </span>
                    </td>
                  </tr>
                  {toShowUV ? (
                    <tr>
                      <td colSpan={8} className="no-brdr-x text-end">
                        <span
                          style={{
                            position: "sticky",
                            right: "20px",
                          }}
                        >
                          ₹{underlying?.netChange}
                          <br />
                          Trend: {underlying.goc_index}
                        </span>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* 

        strike prices

      */}
      <div
        className="tabular-strk-container"
        id="tableStrk-container"
        ref={table2ContainerRef}
      >
        <table id="tableStrk" className=" zoo-optionChn">
          <thead>
            <tr>
              <td className="no-brdr-x">
                STRIKE
                <hr className="m-0" />
                <span style={{ fontSize: "8px", textWrap: "nowrap" }}>
                  OI | VOL PCR
                </span>
              </td>
            </tr>
          </thead>
          <tbody>
            {Object.keys(data).map((val, index) => {
              const strikePrice = parseFloat(val);
              const nxtStrkPrc =
                data[Object.keys(data)[index + 1]]?.CE?.strikePrice;
              let toShowUV = isBetween(
                parseFloat(underlyingValue),
                strikePrice,
                parseFloat(nxtStrkPrc)
              );
              const pcr = (
                parseFloat(data[val]?.PE?.openInterest || 0) /
                  parseFloat(data[val]?.CE?.openInterest || 0) || 0
              ).toFixed(2);
              const pcrVol = (
                parseFloat(data[val]?.PE?.totalTradedVolume || 0) /
                  parseFloat(data[val]?.CE?.totalTradedVolume || 0) || 0
              ).toFixed(2);

              return (
                <React.Fragment key={`QF_OC_INDEX_KEY_STRK_${index}`}>
                  <tr>
                    <td className="no-brdr-x text-center">
                      {strikePrice}
                      <br />
                      <span
                        style={{
                          fontSize: "8px",
                          textWrap: "nowrap",
                        }}
                      >
                        {groupINRCurrencyNSE(pcr)} |{" "}
                        {groupINRCurrencyNSE(pcrVol)}
                      </span>
                    </td>
                  </tr>
                  {toShowUV ? (
                    <tr>
                      <td className="no-brdr-x">₹{underlyingValue}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* 

        PUT

      */}

      <div
        className="tabular-container"
        ref={table3ContainerRef}
        id="tablePut-container"
      >
        <table id="tablePut" className=" zoo-optionChn">
          <thead>
            <tr>
              <td>LTP</td>
              <td>AVG</td>
              <td>OI</td>
              <td>VOL</td>
              <td>DELTA</td>
              <td>GAMMA</td>
              <td>THETA</td>
              <td>VEGA</td>
            </tr>
          </thead>
          <tbody>
            {Object.keys(data).map((val, index) => {
              const strikePrice = parseFloat(val);
              const underlyingValue =
                data[Object.keys(data)[0]]?.CE?.underlyingValue;
              const isPutITM = strikePrice > underlyingValue;
              const putBgColor = isPutITM
                ? chartColor.opacGreenMore
                : chartColor.opacRedMore;
              const nxtStrkPrc =
                data[Object.keys(data)[index + 1]]?.CE?.strikePrice;
              let toShowUV = isBetween(
                parseFloat(underlyingValue),
                strikePrice,
                parseFloat(nxtStrkPrc)
              );

              return (
                <React.Fragment key={`QF_OC_INDEX_KEY_PUT_${index}`}>
                  <tr
                    onClick={() => {
                      setSelectedOption([val, "PE"]);
                    }}
                  >
                    <td
                      style={{
                        backgroundColor: putBgColor,
                        textAlign: "right",
                      }}
                    >
                      {groupINRCurrencyNSE(data[val]?.PE?.lastPrice, true) ||
                        "-"}
                      <br />
                      <span>
                        {groupINRCurrencyNSE(data[val]?.PE?.pChange)}%
                      </span>
                    </td>

                    <td
                      style={{
                        backgroundColor: putBgColor,
                      }}
                    >
                      {groupINRCurrencyNSE(data[val]?.PE?.avgPrice, true) ||
                        "-"}
                    </td>
                    <td
                      style={{
                        backgroundColor:
                          strikePrice < underlyingValue
                            ? decideColor(
                                strikePrice,
                                topThrees.put.byOI,
                                putBgColor
                              )
                            : putBgColor,
                        textAlign: "right",
                      }}
                    >
                      {groupINRCurrencyNSE(data[val]?.PE?.openInterest) || "-"}
                      <br />
                      <span>
                        {groupINRCurrencyNSE(
                          data[val]?.PE?.changeinOpenInterest
                        )}
                      </span>
                    </td>
                    <td
                      style={{
                        backgroundColor:
                          strikePrice < underlyingValue
                            ? decideColor(
                                strikePrice,
                                topThrees.put.byVolume,
                                putBgColor
                              )
                            : putBgColor,
                        textAlign: "right",
                      }}
                    >
                      {groupINRCurrencyNSE(data[val]?.PE?.totalTradedVolume) ||
                        "-"}
                      <br />
                      <span>
                        IV:
                        {data[val]?.PE?.impliedVolatility?.toFixed(2)}
                      </span>
                    </td>
                    <td
                      style={{
                        backgroundColor: putBgColor,
                      }}
                    >
                      {data[val]?.PE?.delta?.toFixed(4) || "-"}
                    </td>

                    <td
                      style={{
                        backgroundColor: putBgColor,
                      }}
                    >
                      {data[val]?.PE?.gamma?.toFixed(4) || "-"}
                    </td>
                    <td
                      style={{
                        backgroundColor: putBgColor,
                      }}
                    >
                      {data[val]?.PE?.theta?.toFixed(4) || "-"}
                    </td>
                    <td
                      style={{
                        backgroundColor: putBgColor,
                      }}
                    >
                      {data[val]?.PE?.vega?.toFixed(4) || "-"}
                    </td>
                  </tr>
                  {toShowUV ? (
                    <tr>
                      <td colSpan={8} className="no-brdr-x text-start">
                        <span
                          style={{
                            position: "sticky",
                            left: "20px",
                          }}
                          title={underlying.goc_action}
                        >
                          {underlying.percentChange}%
                          <br />
                          Momentum: {(underlying.goc_score * 100).toFixed(2)}
                          {/* {" | "}
                          Stategy to use: {underlying.goc_action} */}
                        </span>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

      {selectedOption[0] && selectedOption[1] ? (
        <>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              //   backgroundColor: "var(--text-color-opac)",
              backdropFilter: "blur(1px)",
              top: "0",
              right: "0",
            }}
            onClick={() => {
              setSelectedOption([undefined, undefined]);
            }}
          ></div>

          <div
            className="glassmorfy-it"
            style={{
              position: "absolute",
              bottom: "10px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "300px",
              maxWidth: "90%",
              minHeight: "100px",
              //   backgroundColor: "var(--body-bg)",
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
              padding: "10px",
            }}
          >
            Selected Option {selectedOption[0]} {selectedOption[1]}
            <br />
            <div className="row mt-2 px-2">
              <div className="col-6">
                <div className="input-group">
                  <input
                    type="number"
                    className="qfInputType pl-3 form-control"
                    style={{
                      borderLeft: borderStyle,
                      borderTop: borderStyle,
                      borderBottom: borderStyle,
                      maxHeight: "30px",
                    }}
                    placeholder="Lots"
                    aria-label="Lots"
                    aria-describedby={`lots_${selectedOption[0]}_${selectedOption[1]}`}
                    value={selectedOptionLots}
                    min={1}
                    onChange={(e) => {
                      setSelectedOptionLots(e.target.value);
                    }}
                  />
                  <div className="input-group-append ">
                    <span
                      className="input-group-text qfInputType-addon-input-label p-2"
                      id={`lots_${selectedOption[0]}_${selectedOption[1]}`}
                      style={{
                        maxHeight: "30px",
                        borderTopLeftRadius: "unset !important",
                        borderBottomLeftRadius: "unset !important",
                      }}
                    >
                      x{underlying.lotsize}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-6 text-end">
                LTP:{" "}
                {groupINRCurrencyNSE(
                  data[selectedOption[0]][selectedOption[1]]?.lastPrice
                ) || "-"}
              </div>
              <div className="col-6 py-2">
                <button
                  type="button"
                  className="btn-sm w-100 btn btn-success"
                  style={{
                    border: borderStyle,
                  }}
                  onClick={() => {
                    derivativesStore.dispatch(
                      addOptionLeg({
                        ...data[selectedOption[0]][selectedOption[1]],
                        expiryDate: Exp,
                        action: "B",
                        lots: selectedOptionLots,
                      })
                    );
                    setSelectedOptionLots(1);
                  }}
                >
                  BUY
                </button>
              </div>
              <div className="col-6 py-2">
                <button
                  type="button"
                  className="btn-sm w-100 btn btn-danger"
                  style={{
                    border: borderStyle,
                  }}
                  onClick={() => {
                    derivativesStore.dispatch(
                      addOptionLeg({
                        ...data[selectedOption[0]][selectedOption[1]],
                        expiryDate: Exp,
                        action: "S",
                        lots: selectedOptionLots,
                      })
                    );
                    setSelectedOptionLots(1);
                  }}
                >
                  SELL
                </button>
              </div>
              <div className="col-12 py-2">
                <GetOpenPositionFor
                  strike={
                    data[selectedOption[0]][selectedOption[1]]?.strikePrice
                  }
                  type={data[selectedOption[0]][selectedOption[1]]?.optionType}
                  exp={Exp}
                  lotsize={underlying.lotsize}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default OptionChainViewer;
