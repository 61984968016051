import React, { useState } from "react";
import { commonApiDelete } from "../../../utils/api";
import { notificationTopup } from "../../../utils/NotificationTopup";
import { Link, useNavigate } from "react-router-dom";
import { chartColor, isMobile } from "../../../utils/configs";
import ModalQF from "../../vision/ModalQF";

const WatchlistTab = ({
  watchlistDetails,
  shortBig = "short",
  handleUpdater,
  selectedTab,
  isSharedWL = false,
}) => {
  const n = useNavigate();
  const [selectedScanners, setSelectedScanners] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStock, setSelectedStock] = useState("");

  return (
    <div
      className="px-2"
      style={{
        ...(shortBig === "short"
          ? {
              overflowY: "scroll",
              maxHeight: "200px",
            }
          : { overflow: "scroll", maxHeight: "78vh" }),
      }}
    >
      <table className="w-100 table-tr-bb table-px akp-zoom">
        <thead className="make-me-sticky non-trans-bg">
          <tr>
            <th>STK</th>
            <th>Price</th>
            <th>Surveillance</th>
            {shortBig === "big" && (
              <>
                <th>Scanner</th>
                <th>Industry</th>
                <th>PE</th>
                <th
                  style={{
                    textWrap: "nowrap",
                  }}
                >
                  Face Value
                </th>
                <th>Intraday</th>
                <th>FNO</th>
                <th title="Delivery To Trade Ratio">DTT</th>
              </>
            )}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {(
            watchlistDetails.filter((val) => selectedTab === val.id)[0]
              ?.items || []
          )
            .sort((a, b) => {
              if (a.symbol < b.symbol) return -1; // a comes before b
              if (a.symbol > b.symbol) return 1; // a comes after b
              return 0;
            })
            .map((itemstk, indexstk) => {
              let ltp = itemstk.data.ltp.toFixed(2);
              let netChange = itemstk.data.netChange;
              let perChange = itemstk.data.percentChange;
              return (
                <tr key={indexstk} className="tr-hover-focus-selection">
                  <td>
                    <Link
                      to={
                        itemstk.data
                          ? `/info/${itemstk.data.exchange}/${itemstk.data.tradingSymbol}`
                          : `/`
                      }
                      style={{
                        fontWeight: "900",
                        fontSize: "12px",
                        color: "var(--text-color)",
                        textDecoration: "none",
                      }}
                    >
                      {itemstk.symbol}
                    </Link>
                  </td>
                  <td>
                    {ltp}{" "}
                    {shortBig === "big" && (
                      <>
                        <br />
                        <span
                          style={{
                            fontSize: "8px",
                            textWrap: "nowrap",
                            color:
                              netChange >= 0
                                ? chartColor.green
                                : chartColor.red,
                            fontWeight: 700,
                          }}
                        >
                          ₹{netChange} ({perChange}%)
                        </span>
                      </>
                    )}
                  </td>
                  <td>{itemstk.stock?.surveillance?.surv || "-"}</td>
                  {shortBig === "big" && (
                    <>
                      <td>
                        {itemstk.scanner.length > 0 ? (
                          <>
                            {itemstk.scanner.length}
                            <i
                              className="fas fa-bars-staggered ms-2"
                              onClick={() => {
                                setSelectedScanners(itemstk.scanner);
                                setSelectedStock(itemstk.symbol);
                                setIsModalOpen(true);
                              }}
                            />
                          </>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>{itemstk.stock?.industry || "-"}</td>
                      <td>{itemstk.stock?.pdSymbolPe || "-"}</td>
                      <td className="text-center">
                        {itemstk.stock?.faceValue || "-"}
                      </td>
                      <td>
                        {itemstk.data.tradingSymbol.endsWith("-EQ")
                          ? "Yes"
                          : "No"}
                      </td>
                      <td>{itemstk.stock?.derivatives}</td>
                      <td>{itemstk.info.deliveryToTradedQuantity}%</td>
                    </>
                  )}

                  {/* <td></td> */}
                  {isSharedWL ? (
                    <td></td>
                  ) : (
                    <td
                      style={{
                        textAlign: "center",
                        cursor: "pointer",
                        color: chartColor.red,
                      }}
                      onClick={() => {
                        if (
                          confirm(
                            `You are removing stock ${itemstk.symbol} from selected whatchlist. You want to proceed further?`
                          )
                        ) {
                          commonApiDelete(
                            `/user/watchlist/${selectedTab}/deletestock?symbol=${itemstk.symbol}`
                          )
                            .then((res) => {
                              notificationTopup(res.data.message, "green");
                              handleUpdater();
                            })
                            .catch((err) => {
                              notificationTopup(err.response.data.error);
                            });
                        }
                      }}
                    >
                      <i className="fas fa-heart-circle-xmark" />
                    </td>
                  )}
                </tr>
              );
            })}
        </tbody>
      </table>
      {isModalOpen && (
        <div>
          <ModalQF
            responsive={isMobile() ? false : true}
            // responsiveH={true}
            dataClearFunc={setIsModalOpen}
            dataClearTo={false}
            header={selectedStock}
            desc={`Appeared in ${selectedScanners.length} Scanners`}
            closeVar={setIsModalOpen}
            closeVal={false}
            data={
              <div
                style={{
                  maxHeight: "60vh",
                  overflowY: "scroll",
                  textAlign: "justify",
                  paddingRight: "10px",
                }}
              >
                <ol>
                  {selectedScanners.map((val, index) => {
                    return <li key={index}>{val}</li>;
                  })}
                </ol>
                <center>
                  <button
                    onClick={() => {
                      n("/fundamental-scanners");
                    }}
                    className="btn btn-info my-2"
                  >
                    Jump to Scanners
                  </button>
                </center>
              </div>
            }
            isExpandBtn={false}
            closeBtn={true}
          />
        </div>
      )}
    </div>
  );
};

export default WatchlistTab;
