import React from "react";
import DerivativesPages from "./DerivativesPages";
import DerivativesOC from "./DerivativesOC";

const Derivatives = () => {
  return (
    <div
      className="general-nmphc-des px-2 py-3"
      style={{
        height: "79vh",
        overflowY: "scroll",
      }}
    >
      <div className="row p-0 m-0 h-fill-available">
        <div className="col-md-6 h-fill-available">
          <DerivativesOC />
        </div>
        <div className="col-md-6">
          <DerivativesPages />
        </div>
      </div>
    </div>
  );
};

export default Derivatives;
