import React, { useEffect, useState } from "react";
import { commonApiDelete, commonApiGet } from "../../../utils/api";
import { notificationTopup } from "../../../utils/NotificationTopup";
import VisOops from "../VisOops";
import QFLoader from "../QFLoader";
import VisAddWatchlist from "./VisAddWatchlist";
import { chartColor, isMobile, maxViewContent } from "../../../utils/configs";
import { Nav, NavItem } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import hawkQuiver, { updateVisWL } from "../../../utils/hawkeye/hawkQuiver";
import { useDispatch, useSelector } from "react-redux";
import WatchlistTab from "../../features/myWatchlists/WatchlistTab";

const VisWatchlist = () => {
  const [watchlistDetails, setWatchlistDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [toShowAddWatchlist, setToShowAddWatchlist] = useState(false);
  const [apiCaller, setApiCaller] = useState(0);

  const [isListClicked, setIsListClicked] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);

  const visWLUp = useSelector((state) => state.visWLUp);

  const d = useDispatch();

  useEffect(() => {
    (async () => {
      await commonApiGet("/user/watchlist", "VISEXPANDED_WATCHLIST", false)
        .then((res) => {
          setWatchlistDetails(res.data);
          hawkQuiver.dispatch(updateVisWL(res.data));
          d({ type: "VIS_WL_UP" });
          setLoading(false);
          setError(false);
        })
        .catch((err) => {
          notificationTopup(err.message);
          setLoading(false);
          setError(true);
        });
    })();
  }, [apiCaller]);

  const handleUpdater = () => {
    setApiCaller(apiCaller + 1);
  };

  useEffect(() => {
    setWatchlistDetails(hawkQuiver.getState().visWatchlist);
  }, [visWLUp]);
  return (
    <div
      className="w-100 py-2"
      style={{
        maxHeight: "400px",
        overflowY: "scroll",
      }}
    >
      {loading ? (
        <QFLoader />
      ) : error ? (
        <VisOops />
      ) : (
        <>
          <div className="py-3">
            {!isListClicked ? (
              <table className="w-100 table-tr-bb table-px akp-zoom">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th
                      style={{
                        textAlign: "center",
                      }}
                      colSpan={2}
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {watchlistDetails.map((item, index) => (
                    <tr
                      key={index}
                      className="tr-hover-focus-selection c-pointer"
                    >
                      <td
                        style={{
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          setIsListClicked(true);
                          setSelectedTab(item.id);
                        }}
                      >
                        {item.name}
                      </td>
                      <td
                        onClick={() => {
                          setIsListClicked(true);
                          setSelectedTab(item.id);
                        }}
                      >
                        {item.desc} | #{item.items?.length || 0} stocks
                      </td>
                      <td
                        onClick={() => {
                          setIsListClicked(true);
                          setSelectedTab(item.id);
                        }}
                        style={{
                          textAlign: "right",
                          color: "var(--bs-info)",
                        }}
                        className="py-1"
                      >
                        <i className="fas fa-angle-right"></i>
                      </td>
                      <td
                        className="py-1"
                        style={{
                          textAlign: "left",
                          color: chartColor.red,
                        }}
                        onClick={() => {
                          if (
                            (item.items || []).length === 0 ||
                            confirm(
                              `You are forwarding for deleting ${
                                item.name
                              }, with having ${
                                item.items?.length || 0
                              } stocks. This action is irreversible. You want to proceed further?`
                            )
                          ) {
                            setLoading(true);
                            commonApiDelete(`/user/watchlist/${item.id}`)
                              .then((res) => {
                                notificationTopup(res.data.message, "green");
                                setApiCaller(apiCaller + 1);
                              })
                              .catch((err) => {
                                notificationTopup(err.message);
                              });
                          }
                        }}
                      >
                        <i className="fas fa-trash"></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div>
                <Nav
                  tabs
                  style={{
                    color: "var(--text-color) !important",
                    ...(isMobile()
                      ? {
                          display: "flex",
                          flexWrap: "nowrap",
                          textWrap: "nowrap",
                          overflowX: "scroll",
                        }
                      : {}),
                  }}
                  className="mb-3 section-bb"
                >
                  {watchlistDetails.map((item, index) => (
                    <NavItem
                      key={index}
                      onClick={() => {
                        setSelectedTab(item.id);
                      }}
                      style={{
                        backgroundColor:
                          selectedTab === item.id
                            ? "var(--text-color)"
                            : "transparent",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                      className="px-2"
                    >
                      <NavLink
                        active={selectedTab === item.id ? true : false}
                        style={{
                          fontSize: "16px",
                          color:
                            selectedTab === item.id
                              ? "var(--body-bg)"
                              : "var(--text-color)",
                        }}
                      >
                        {maxViewContent(item.name, 10)}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>

                <WatchlistTab
                  watchlistDetails={watchlistDetails}
                  handleUpdater={handleUpdater}
                  selectedTab={selectedTab}
                />
              </div>
            )}
          </div>
        </>
      )}
      <center>
        {toShowAddWatchlist ? (
          <VisAddWatchlist
            closer={setToShowAddWatchlist}
            handleUpdater={handleUpdater}
          />
        ) : (
          <>
            {isListClicked ? (
              <button
                type="button"
                className="btn-sm rounded-pill px-4 m-2 general-nmphc-des our-border btn-hover-effect"
                style={{
                  fontWeight: 400,
                  color: "var(--text-color)",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  setIsListClicked(false);
                }}
              >
                Back
              </button>
            ) : (
              <></>
            )}
            <button
              type="button"
              className="btn-sm rounded-pill px-4 m-2 general-nmphc-des our-border btn-hover-effect "
              style={{
                fontWeight: 400,
                color: "var(--text-color)",
                fontWeight: "bold",
              }}
              onClick={() => {
                setToShowAddWatchlist(true);
              }}
            >
              + Add Watchlist
            </button>
          </>
        )}
      </center>
    </div>
  );
};

export default VisWatchlist;
