export const dataPrep = (data, lengthOfExps, strikePrices) => {
  // Prepare data for CE and PE
  const strikePriceMapCEOI = {};
  const strikePriceMapPEOI = {};
  const strikePriceMapCEChange = {};
  const strikePriceMapPEChange = {};
  const strikePriceMapCEIV = {};
  const strikePriceMapPEIV = {};
  const strikePriceMapCEbQuan = {};
  const strikePriceMapPEbQuan = {};
  const strikePriceMapCEsQuan = {};
  const strikePriceMapPEsQuan = {};
  const strikePriceMapCEVolume = {};
  const strikePriceMapPEVolume = {};
  const expiryDates = new Set(); // To keep track of expiry dates

  // Iterate through the data array and aggregate open interest and change in open interest
  data.forEach((item) => {
    expiryDates.add(item.expiryDate); // Collect expiry dates

    // Aggregate open interest
    if (item.optionType === "CE") {
      if (!strikePriceMapCEOI[item.strikePrice]) {
        strikePriceMapCEOI[item.strikePrice] = 0;
      }
      strikePriceMapCEOI[item.strikePrice] += item.openInterest;

      // Aggregate change in open interest
      if (!strikePriceMapCEChange[item.strikePrice]) {
        strikePriceMapCEChange[item.strikePrice] = 0;
      }
      strikePriceMapCEChange[item.strikePrice] += item.changeinOpenInterest;

      // Aggregate IV
      if (!strikePriceMapCEIV[item.strikePrice]) {
        strikePriceMapCEIV[item.strikePrice] = 0;
      }
      strikePriceMapCEIV[item.strikePrice] += item.impliedVolatility;

      if (!strikePriceMapCEbQuan[item.strikePrice]) {
        strikePriceMapCEbQuan[item.strikePrice] = 0;
      }
      strikePriceMapCEbQuan[item.strikePrice] += item.totalBuyQuantity;

      if (!strikePriceMapCEsQuan[item.strikePrice]) {
        strikePriceMapCEsQuan[item.strikePrice] = 0;
      }
      strikePriceMapCEsQuan[item.strikePrice] += item.totalSellQuantity;

      // Volume
      if (!strikePriceMapCEVolume[item.strikePrice]) {
        strikePriceMapCEVolume[item.strikePrice] = 0;
      }
      strikePriceMapCEVolume[item.strikePrice] += item.totalTradedVolume;
    } else if (item.optionType === "PE") {
      if (!strikePriceMapPEOI[item.strikePrice]) {
        strikePriceMapPEOI[item.strikePrice] = 0;
      }
      strikePriceMapPEOI[item.strikePrice] += item.openInterest;

      // Aggregate change in open interest
      if (!strikePriceMapPEChange[item.strikePrice]) {
        strikePriceMapPEChange[item.strikePrice] = 0;
      }
      strikePriceMapPEChange[item.strikePrice] += item.changeinOpenInterest;

      // Aggregate IV
      if (!strikePriceMapPEIV[item.strikePrice]) {
        strikePriceMapPEIV[item.strikePrice] = 0;
      }
      strikePriceMapPEIV[item.strikePrice] += item.impliedVolatility;

      if (!strikePriceMapPEbQuan[item.strikePrice]) {
        strikePriceMapPEbQuan[item.strikePrice] = 0;
      }
      strikePriceMapPEbQuan[item.strikePrice] += item.totalBuyQuantity;

      if (!strikePriceMapPEsQuan[item.strikePrice]) {
        strikePriceMapPEsQuan[item.strikePrice] = 0;
      }
      strikePriceMapPEsQuan[item.strikePrice] += item.totalSellQuantity;

      // Volume
      if (!strikePriceMapPEVolume[item.strikePrice]) {
        strikePriceMapPEVolume[item.strikePrice] = 0;
      }
      strikePriceMapPEVolume[item.strikePrice] += item.totalTradedVolume;
    }
  });

  const ceOpenInterest = strikePrices.map(
    (price) => strikePriceMapCEOI[price] || 0
  );
  const peOpenInterest = strikePrices.map(
    (price) => strikePriceMapPEOI[price] || 0
  );

  // Prepare change in open interest data
  const ceChangeInOpenInterest = strikePrices.map(
    (price) => strikePriceMapCEChange[price] || 0
  );
  const peChangeInOpenInterest = strikePrices.map(
    (price) => strikePriceMapPEChange[price] || 0
  );

  // Prepare IV data
  const ceIV = strikePrices.map(
    (price) => (strikePriceMapCEIV[price] || 0) / lengthOfExps
  );
  const peIV = strikePrices.map(
    (price) => (strikePriceMapPEIV[price] || 0) / lengthOfExps
  );

  // Prepare Volume
  const ceVol = strikePrices.map(
    (price) => (strikePriceMapCEVolume[price] || 0) / lengthOfExps
  );
  const peVol = strikePrices.map(
    (price) => (strikePriceMapPEVolume[price] || 0) / lengthOfExps
  );

  // Prepare BSCEPE data
  const ceBuy = strikePrices.map((price) => strikePriceMapCEbQuan[price] || 0);
  const ceSell = strikePrices.map((price) => strikePriceMapCEsQuan[price] || 0);
  const peBuy = strikePrices.map((price) => strikePriceMapPEbQuan[price] || 0);
  const peSell = strikePrices.map((price) => strikePriceMapPEsQuan[price] || 0);

  const combinedIV = strikePrices.map((_, index) => {
    const ceValue = ceIV[index] || 0; // CE IV value at the current index
    const peValue = peIV[index] || 0; // PE IV value at the current index
    const totalIV = ceValue + peValue;
    const count = (ceValue > 0 ? 1 : 0) + (peValue > 0 ? 1 : 0); // Count only non-zero values
    return count > 0 ? totalIV / count : 0; // Average IV if any value exists
  });

  // After preparing ceOpenInterest and peOpenInterest
  const totalCeOpenInterest = ceOpenInterest.reduce((acc, val) => acc + val, 0);
  const totalPeOpenInterest = peOpenInterest.reduce((acc, val) => acc + val, 0);

  // Calculate PCR and PCD
  const pcr =
    totalCeOpenInterest > 0 ? totalPeOpenInterest / totalCeOpenInterest : 0; // Avoid division by zero
  const pcd = totalPeOpenInterest - totalCeOpenInterest;

  // Prepare individual PCR data for each strike price
  const pcrData = strikePrices.map((price) => {
    const ceOpenInterestAtPrice = strikePriceMapCEOI[price] || 0; // Call open interest for the strike price
    const peOpenInterestAtPrice = strikePriceMapPEOI[price] || 0; // Put open interest for the strike price
    return ceOpenInterestAtPrice > 0
      ? peOpenInterestAtPrice / ceOpenInterestAtPrice
      : 0; // Calculate PCR for the strike price
  });

  // Prepare the data for PCD
  const pcdData = strikePrices.map((price) => {
    const ceOpenInterestAtPrice = strikePriceMapCEOI[price] || 0; // Call open interest for the strike price
    const peOpenInterestAtPrice = strikePriceMapPEOI[price] || 0; // Put open interest for the strike price
    return peOpenInterestAtPrice - ceOpenInterestAtPrice; // Calculate PCD for the strike price
  });

  return {
    ceOpenInterest,
    peOpenInterest,
    ceChangeInOpenInterest,
    peChangeInOpenInterest,
    ceIV,
    peIV,
    combinedIV,
    pcrData,
    pcdData,
    pcr,
    pcd,
    ceBuy,
    ceSell,
    peBuy,
    peSell,
    ceVol,
    peVol,
  };
};
