import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import VisOops from "../../vision/VisOops";
import QFLoader from "../../vision/QFLoader";
import {
  chartColor,
  decideColor,
  isBetween,
  isMarketOpen,
} from "../../../utils/configs";
import { groupINRCurrencyNSE } from "../../../utils/functions";
import derivativesStore, {
  updateSelectedExpiry,
  updateSelectedIndex,
  updateSelectedOptionExpiryDates,
  updateCurrData,
  addOptionLeg,
  clearOptionsLeg,
} from "./derivatives-store";
import { commonApiGet } from "../../../utils/api";
import { notificationTopup } from "../../../utils/NotificationTopup";
import OptionChainViewer from "./OptionChainViewer";

const indicesToDerive = ["NIFTY", "BANKNIFTY", "FINNIFTY", "SENSEX", "BANKEX"];

const DerivativesOC = () => {
  const tableContainerRef = useRef(null);

  const [underlying, setUnderlying] = useState({});

  const [optionChain, setOptionChain] = useState({ data: [], Exp: ["date"] });
  const [optionExDates, setOptionExDates] = useState([]);
  const [option, setOption] = useState(
    derivativesStore.getState().selectedIndex
  );
  const [loaderShow, setLoaderShow] = useState(true);
  const [errorShow, setErrorShow] = useState(false);
  const [dropdownOptionOpen, setDropdownOptionOpen] = useState(false);
  const [dropdownOptionOpenDates, setDropdownOptionOpenDates] = useState(false);
  const [optionsDate, setOptionsDate] = useState(undefined);
  const [callCheck, setCallCheck] = useState(0);
  const [toScrollSetMiddleHR, setToScrollSetMiddleHR] = useState(-1);

  const [isOneTimeAPICalled, setIsOneTimeAPICalled] = useState(false);

  const isOneTimeAPICalledRef = useRef(isOneTimeAPICalled); // Create a ref

  // Update the ref whenever isOneTimeAPICalled changes
  useEffect(() => {
    isOneTimeAPICalledRef.current = isOneTimeAPICalled;
  }, [isOneTimeAPICalled]);

  const [topThrees, setTopThrees] = useState({
    call: {
      byVolume: [],
      byOI: [],
    },
    put: {
      byVolume: [],
      byOI: [],
    },
  });

  const ddootoggle = useCallback(
    () => setDropdownOptionOpen((prevState) => !prevState),
    []
  );
  const ddootoggleDates = useCallback(
    () => setDropdownOptionOpenDates((prevState) => !prevState),
    []
  );

  const [apiChecker, setApiChecker] = useState(0);

  const fetchDates = async () => {
    await commonApiGet(`/websock/expiry/${option}`)
      .then((res) => {
        setOptionExDates(res.data);
        setOptionsDate(res.data[0] || "date");
        derivativesStore.dispatch(updateSelectedExpiry(res.data[0] || "date"));
        derivativesStore.dispatch(updateSelectedOptionExpiryDates(res.data));
      })
      .catch((err) => {
        notificationTopup(err.message);
      });
  };

  const fetchOptionChain = async () => {
    if (optionExDates.length === 0 || !optionsDate || optionsDate === "date")
      return;

    const marketOpen = await isMarketOpen();

    if (!marketOpen && isOneTimeAPICalledRef.current) return;

    const expiryParam = optionsDate ? `?expiry=${optionsDate}` : "";
    const url = `/websock/${option}${expiryParam}`;

    await commonApiGet(url, "OPTION_CHAIN_API", false)
      .then((res) => {
        if (res.data.error) {
          setLoaderShow(false);
          notificationTopup(res.data.error);
        } else {
          let data = res.data;

          if (data.message !== undefined) {
            setErrorShow(true);
            setLoaderShow(false);
            return;
          }

          const preData = data.optionChain?.reduce((acc, curr) => {
            const { strikePrice, optionType } = curr;
            if (!acc[strikePrice]) acc[strikePrice] = {};
            acc[strikePrice][optionType] = curr;
            return acc;
          }, {});

          setOptionChain({
            data: preData,
            Exp: optionsDate,
          });

          setLoaderShow(false);
          setErrorShow(false);
          setUnderlying(data.underlying);
          console.log(data);
          derivativesStore.dispatch(updateCurrData(data));
          setIsOneTimeAPICalled(true);
        }
      })
      .catch((error) => {
        if (!isOneTimeAPICalledRef.current) {
          notificationTopup(error.message);
          setErrorShow(true);
          setLoaderShow(false);
        }
      });
  };

  useEffect(() => {
    (async () => {
      await fetchDates();
    })();
  }, [option]);

  useEffect(() => {
    setLoaderShow(true);
    fetchOptionChain(); // Initial fetch
    const interval = setInterval(fetchOptionChain, 15000); // Refresh every 15 seconds

    return () => {
      clearInterval(interval); // Clean up the interval on unmount
    };
  }, [apiChecker, optionsDate]); // Add dependencies as needed

  const strikePrices = useMemo(() => {
    return optionChain.data
      ? Object.keys(optionChain.data).map(parseFloat)
      : [];
  }, [optionChain]);

  const underlyingValue = useMemo(() => {
    return optionChain.data
      ? optionChain.data[Object.keys(optionChain.data)[0]]?.CE?.underlyingValue
      : null;
  }, [optionChain]);

  const calculateNearestStrikePrice = useMemo(() => {
    if (!optionChain.data || !underlyingValue) return null;
    return strikePrices.reduce((prev, curr) =>
      Math.abs(curr - underlyingValue) < Math.abs(prev - underlyingValue)
        ? curr
        : prev
    );
  }, [strikePrices, underlyingValue]);

  const getTop3OIOTM = (optionChain, underlyingValue) => {
    if (!optionChain.data || !underlyingValue)
      return { topCallOI: [], topPutOI: [], topCallVol: [], topPutVol: [] };

    // Separate Call OTM and Put OTM
    const callOTM = [];
    const putOTM = [];

    Object.keys(optionChain.data).forEach((strikePrice) => {
      const price = parseFloat(strikePrice);
      const callData = optionChain.data[strikePrice]?.CE;
      const putData = optionChain.data[strikePrice]?.PE;

      if (price > underlyingValue && callData) {
        // Call is OTM when strikePrice > underlyingValue
        callOTM.push({ strikePrice: price, ...callData });
      }

      if (price < underlyingValue && putData) {
        // Put is OTM when strikePrice < underlyingValue
        putOTM.push({ strikePrice: price, ...putData });
      }
    });

    // Sort the OTM options by open interest (OI) in descending order and return the top 3
    const topCallOI = callOTM
      .sort((a, b) => b.openInterest - a.openInterest)
      .slice(0, 3);

    const topPutOI = putOTM
      .sort((a, b) => b.openInterest - a.openInterest)
      .slice(0, 3);

    // Sort the OTM options by total traded volume in descending order and return the top 3
    const topCallVol = callOTM
      .sort((a, b) => b.totalTradedVolume - a.totalTradedVolume)
      .slice(0, 3);

    const topPutVol = putOTM
      .sort((a, b) => b.totalTradedVolume - a.totalTradedVolume)
      .slice(0, 3);

    return { topCallOI, topPutOI, topCallVol, topPutVol };
  };

  useEffect(() => {
    if (optionChain.data && underlyingValue) {
      const { topCallOI, topPutOI, topCallVol, topPutVol } = getTop3OIOTM(
        optionChain,
        underlyingValue
      );
      setTopThrees({
        call: {
          byVolume: topCallVol.map((val) => val.strikePrice),
          byOI: topCallOI.map((val) => val.strikePrice),
        },
        put: {
          byVolume: topPutVol.map((val) => val.strikePrice),
          byOI: topPutOI.map((val) => val.strikePrice),
        },
      });
    }
  }, [optionChain, underlyingValue]);

  useEffect(() => {
    if (toScrollSetMiddleHR < 1 && calculateNearestStrikePrice > 0) {
      const tableContainer = tableContainerRef.current;
      const index = strikePrices.indexOf(calculateNearestStrikePrice);
      if (tableContainer)
        tableContainer.scrollTop =
          index * 45 + 25 - tableContainer.clientHeight / 2;
    }
  }, [toScrollSetMiddleHR, strikePrices, calculateNearestStrikePrice]);

  useEffect(() => {
    derivativesStore.dispatch(clearOptionsLeg());
  }, [option]);

  return (
    <div className="h-fill-available">
      <div
        className="tab-weight text-end mb-3"
        style={{
          zIndex: "2",
        }}
      >
        <ButtonDropdown isOpen={dropdownOptionOpen} toggle={ddootoggle}>
          <DropdownToggle
            caret
            size="sm"
            color="transparent"
            className="general-nmphc-des text-color-themed mt-1 mx-2"
            style={{ fontSize: "12px" }}
          >
            {option}
          </DropdownToggle>
          <DropdownMenu
            className="general-nmphc-des non-trans-bg"
            style={{ maxHeight: "220px", overflowY: "scroll", zIndex: 2 }}
          >
            {indicesToDerive.map((val, key) => (
              <DropdownItem
                key={key}
                className="bg-set-on-active text-color-themed"
                onClick={() => {
                  setOption(val);
                  setLoaderShow(true);
                  setOptionChain({});
                  setOptionsDate(undefined);
                  setToScrollSetMiddleHR(-1);
                  setApiChecker(apiChecker + 1);
                  setIsOneTimeAPICalled(false);
                  derivativesStore.dispatch(updateSelectedIndex(val));
                }}
                disabled={val === option}
                style={{ fontSize: "12px" }}
              >
                {val}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </ButtonDropdown>

        <ButtonDropdown
          isOpen={dropdownOptionOpenDates}
          toggle={ddootoggleDates}
        >
          <DropdownToggle
            caret
            size="sm"
            color="transparent"
            className="general-nmphc-des text-color-themed mt-1"
            style={{ fontSize: "12px" }}
          >
            {optionsDate || "date"}
          </DropdownToggle>
          <DropdownMenu
            className="general-nmphc-des non-trans-bg"
            style={{ maxHeight: "220px", overflowY: "scroll", zIndex: 2 }}
          >
            {optionExDates.map((val, key) => (
              <DropdownItem
                key={key}
                className="bg-set-on-active text-color-themed"
                onClick={() => {
                  setOptionsDate(val);
                  setLoaderShow(true);
                  setOptionChain({});
                  setCallCheck(callCheck + 1);
                  setToScrollSetMiddleHR(-1);
                  setApiChecker(apiChecker + 1);
                  setIsOneTimeAPICalled(false);
                  derivativesStore.dispatch(updateSelectedExpiry(val));
                }}
                disabled={val === optionsDate}
                style={{ fontSize: "12px" }}
              >
                {val}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </ButtonDropdown>
      </div>

      {errorShow ? (
        <VisOops />
      ) : loaderShow ? (
        <QFLoader />
      ) : !optionChain || optionChain == {} || !optionChain.data ? (
        <></>
      ) : (
        <>
          <div
            className="row p-0 m-0 table-td-brdr table-th-brdr section-bb"
            ref={tableContainerRef}
            style={{
              overflow: "hidden",
            }}
          >
            <OptionChainViewer
              optionChain={optionChain}
              topThrees={topThrees}
              underlying={underlying}
              underlyingValue={underlyingValue}
              strikePrices={strikePrices}
              toScrollSetMiddleHR={toScrollSetMiddleHR}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DerivativesOC;
