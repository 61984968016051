export function calculateMaxPain(strikePrices, callOI, putOI) {
    let maxPain = null;
    let minTotalPain = Infinity;

    // Iterate through each strike price to evaluate its pain
    strikePrices.forEach((currentStrikePrice) => {
        let totalPain = 0;

        // Calculate Call Pain
        strikePrices.forEach((strikePrice, index) => {
            if (strikePrice > currentStrikePrice) {
                totalPain += callOI[index] * (strikePrice - currentStrikePrice); // OTM Calls
            }
        });

        // Calculate Put Pain
        strikePrices.forEach((strikePrice, index) => {
            if (strikePrice < currentStrikePrice) {
                totalPain += putOI[index] * (currentStrikePrice - strikePrice); // OTM Puts
            }
        });

        // Check if this is the minimum pain
        if (totalPain < minTotalPain) {
            minTotalPain = totalPain;
            maxPain = currentStrikePrice;
        }
    });

    return maxPain;
}

// export function calculatePOP(S, K, sigma, r, T) {
//     // Calculate d1 and d2
//     const d1 = (Math.log(S / K) + (r + Math.pow(sigma, 2) / 2) * T) / (sigma * Math.sqrt(T));
//     const d2 = d1 - sigma * Math.sqrt(T);

//     // Cumulative Distribution Function (N(d2))
//     const Nd2 = jStat.normal.cdf(d2, 0, 1);

//     // POP for Call Option
//     return 1 - Nd2; // For a call option
// }


export const calculatePOP = (strikePrices, totalPayoffs) => {
    // Assuming Gaussian distribution for simplicity
    const positivePayoffs = totalPayoffs.map((payoff, index) => ({
      strikePrice: strikePrices[index],
      payoff,
    })).filter(item => item.payoff >= 0);
  
    const totalCount = totalPayoffs.length;
    const profitableCount = positivePayoffs.length;
  
    // POP as percentage
    const POP = (profitableCount / totalCount) * 100;
    return { positivePayoffs, POP };
  };