import React from "react";
import { Link } from "react-router-dom";

const linkConfig = [
  { path: "/dashboard", text: "Dashboard", iconClass: "fas fa-tachometer-alt" },
  { path: "/my-watchlists", text: "My Watchlists", iconClass: "fas fa-list-check" },
  { path: "/ipo-details", text: "IPO Details", iconClass: "fas fa-chart-line" },
  { path: "/market-holiday", text: "Market Holiday", iconClass: "fas fa-calendar-days" },
  { path: "/intraday-stocks", text: "Intraday Stocks", iconClass: "fas fa-yin-yang" },
  {
    path: "/privacy-policy",
    text: "Privacy Policy",
    iconClass: "fas fa-shield-alt",
  },
  {
    path: "/terms-and-conditions",
    text: "Terms and Conditions",
    iconClass: "fas fa-file-contract",
  },
  // { path: "/crypto-redirector", text: "Crypto", iconClass: "fab fa-bitcoin" },
];

const VisMoreLinks = () => {
  return (
    <div className="link-container">
      {linkConfig.map((link, index) => (
        <div className="link-item" key={index}>
          <Link to={link.path} className="link">
            <i className={link.iconClass}></i>
            <span>{link.text}</span>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default VisMoreLinks;
