import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import QFLoader from "../../vision/QFLoader";
import VisOops from "../../vision/VisOops";
import { commonApiDelete, commonApiGet } from "../../../utils/api";
import { notificationTopup } from "../../../utils/NotificationTopup";
import hawkQuiver, { updateVisWL } from "../../../utils/hawkeye/hawkQuiver";
import VisAddWatchlist from "../../vision/toolbox/VisAddWatchlist";
import WatchlistOverviewer from "./WatchlistOverviewer";
import WatchlistTab from "./WatchlistTab";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { chartColor } from "../../../utils/configs";
import { useNavigate } from "react-router-dom";
import Divider from "../../../views/preLogin/components/Divider";

const MyWatchlists = () => {
  const n = useNavigate();
  const [watchlistDetails, setWatchlistDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [toShowAddWatchlist, setToShowAddWatchlist] = useState(false);
  const [apiCaller, setApiCaller] = useState(0);

  const [watchlistBookmarked, setWatchlistBookmarked] = useState([]);

  const [selectedTab, setSelectedTab] = useState(null);

  const visWLUp = useSelector((state) => state.visWLUp);

  const d = useDispatch();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    (async () => {
      await commonApiGet("/user/watchlist", "VISEXPANDED_WATCHLIST", false)
        .then((res) => {
          setWatchlistDetails(res.data);
          setSelectedTab(res.data[0]?.id || null);
          hawkQuiver.dispatch(updateVisWL(res.data));
          d({ type: "VIS_WL_UP" });
          setLoading(false);
          setError(false);
        })
        .catch((err) => {
          notificationTopup(err.message);
          setLoading(false);
          setError(true);
        });
    })();

    (async () => {
      await commonApiGet(
        "/user/watchlist/watchlists/bookmarked",
        "MYWL_BOOKMARKED",
        false
      )
        .then((res) => {
          setWatchlistBookmarked(res.data);
        })
        .catch((err) => {
          notificationTopup(err.message);
        });
    })();
  }, [apiCaller]);

  const handleUpdater = () => {
    setApiCaller(apiCaller + 1);
  };

  useEffect(() => {
    setWatchlistDetails(hawkQuiver.getState().visWatchlist);
  }, [visWLUp]);
  return (
    <div
      className="w-100 h-100-responsive-lg"
      style={{
        height: "80vh",
        textAlign: "unset !important",
        overflowY: "scroll",
      }}
    >
      {loading ? (
        <div
          className="vert-center"
          style={{
            height: "80vh",
          }}
        >
          <QFLoader />
        </div>
      ) : error ? (
        <VisOops />
      ) : (
        <>
          <div className="row p-2 m-0 h-100 general-nmphc-in-chart-i-des">
            <div
              className="col-lg-2 col-md-3 general-nmphc-des py-2 h-100 d-md-block d-none"
              style={{
                overflow: "hidden",
              }}
            >
              {watchlistDetails.map((item, index) => (
                <div
                  className="row section-bb"
                  key={index}
                  style={{
                    cursor: "pointer",
                    textAlign: "left",
                  }}
                >
                  <div
                    className={`col-10 m-0 vert-center ${
                      item.id === selectedTab
                        ? "tr-hover-focus-selectionDefaultOpac"
                        : "tr-hover-focus-selection"
                    }`}
                    onClick={() => {
                      setSelectedTab(item.id);
                    }}
                    style={{
                      borderBottomRightRadius: "25px",
                      borderTopRightRadius: "25px",
                    }}
                  >
                    {item.name}
                  </div>
                  <div
                    className="col-2 delete-btn-hover-effect py-2"
                    style={{
                      textAlign: "center",
                    }}
                    onClick={() => {
                      if (
                        (item.items || []).length === 0 ||
                        confirm(
                          `You are forwarding for deleting ${
                            item.name
                          }, with having ${
                            item.items?.length || 0
                          } stocks. This action is irreversible. You want to proceed further?`
                        )
                      ) {
                        setLoading(true);
                        commonApiDelete(`/user/watchlist/${item.id}`)
                          .then((res) => {
                            notificationTopup(res.data.message, "green");
                            setApiCaller(apiCaller + 1);
                          })
                          .catch((err) => {
                            notificationTopup(err.message);
                          });
                      }
                    }}
                  >
                    <i className="fas fa-trash"></i>
                  </div>

                  {/* {item.desc} | #{item.items?.length || 0} stocks */}
                </div>
              ))}

              {watchlistBookmarked.length === 0 ? (
                <></>
              ) : (
                <>
                  <Divider role="text" text="Bookmarked" py={2} />

                  {watchlistBookmarked.map((item, index) => {
                    return (
                      <div
                        className="row section-bb"
                        key={`bm_${index}`}
                        style={{
                          cursor: "pointer",
                          textAlign: "left",
                        }}
                      >
                        <div
                          className="col-10 m-0 vert-center tr-hover-focus-selection"
                          onClick={() => {
                            n(`/watchlist/${item.id}`);
                          }}
                          style={{
                            borderBottomRightRadius: "25px",
                            borderTopRightRadius: "25px",
                          }}
                        >
                          {item.name}
                        </div>
                        <div
                          className="col-2 py-2"
                          style={{
                            textAlign: "center",
                            color: chartColor.opacRed,
                          }}
                        >
                          <i className="fas fa-bookmark"></i>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
              <center className="my-5">
                {toShowAddWatchlist ? (
                  <VisAddWatchlist
                    closer={setToShowAddWatchlist}
                    handleUpdater={handleUpdater}
                    invoker="biggerpage"
                  />
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn-sm rounded-pill px-4 m-2 general-nmphc-des our-border btn-hover-effect "
                      style={{
                        fontWeight: 400,
                        color: "var(--text-color)",
                        fontWeight: "bold",
                      }}
                      onClick={() => {
                        setToShowAddWatchlist(true);
                      }}
                    >
                      + Add Watchlist
                    </button>
                  </>
                )}
              </center>
            </div>

            <div className="d-md-none d-block d-flex justify-content-between align-items-start">
              <div>
                {toShowAddWatchlist ? (
                  <div className="pt-3">
                    <VisAddWatchlist
                      closer={setToShowAddWatchlist}
                      handleUpdater={handleUpdater}
                      invoker="biggerpage"
                    />
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn-sm rounded-pill px-4 m-2 general-nmphc-des our-border btn-hover-effect"
                    style={{
                      fontWeight: 400,
                      color: "var(--text-color)",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      setToShowAddWatchlist(true);
                    }}
                  >
                    + Add Watchlist
                  </button>
                )}
              </div>

              <div className="p-2">
                <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle
                    caret
                    size="sm"
                    color="transparent"
                    className="general-nmphc-des text-color-themed"
                  >
                    Watchlist
                  </DropdownToggle>
                  <DropdownMenu className="general-nmphc-des non-trans-bg">
                    {watchlistDetails.map((item, index) => (
                      <DropdownItem
                        key={index}
                        className={`bg-set-on-active text-color-themed ${
                          item.id === selectedTab
                            ? "tr-hover-focus-selectionDefaultOpac"
                            : "tr-hover-focus-selection"
                        }`}
                        onClick={() => {
                          setSelectedTab(item.id);
                        }}
                        style={{
                          cursor: "pointer",
                          textAlign: "left",
                          borderBottomRightRadius: "25px",
                          borderTopRightRadius: "25px",
                        }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <span>{item.name}</span>
                          <div
                            className="delete-btn-hover-effect"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent the dropdown item click from firing
                              if (
                                (item.items || []).length === 0 ||
                                confirm(
                                  `You are forwarding for deleting ${
                                    item.name
                                  }, with having ${
                                    item.items?.length || 0
                                  } stocks. This action is irreversible. Do you want to proceed further?`
                                )
                              ) {
                                setLoading(true);
                                commonApiDelete(`/user/watchlist/${item.id}`)
                                  .then((res) => {
                                    notificationTopup(
                                      res.data.message,
                                      "green"
                                    );
                                    setApiCaller(apiCaller + 1);
                                  })
                                  .catch((err) => {
                                    notificationTopup(err.message);
                                  });
                              }
                            }}
                          >
                            <i className="fas fa-trash"></i>
                          </div>
                        </div>
                      </DropdownItem>
                    ))}

                    {watchlistBookmarked.length === 0 ? (
                      <></>
                    ) : (
                      <>
                        <Divider role="text" text="Bookmarked" py={2} />

                        {watchlistBookmarked.map((item, index) => {
                          return (
                            <DropdownItem
                              key={`bm_${index}`}
                              className="bg-set-on-active text-color-themed tr-hover-focus-selection"
                              style={{
                                cursor: "pointer",
                                textAlign: "left",
                                borderBottomRightRadius: "25px",
                                borderTopRightRadius: "25px",
                              }}
                              onClick={() => {
                                n(`/watchlist/${item.id}`);
                              }}
                            >
                              <div className="d-flex justify-content-between align-items-center">
                                <span>{item.name}</span>
                                <div
                                  className="col-2 py-2"
                                  style={{
                                    textAlign: "center",
                                    color: chartColor.opacRed,
                                  }}
                                >
                                  <i className="fas fa-bookmark"></i>
                                </div>
                              </div>
                            </DropdownItem>
                          );
                        })}
                      </>
                    )}
                  </DropdownMenu>
                </ButtonDropdown>
              </div>
            </div>
            <div
              className="col-lg-10 col-md-9 h-100"
              style={{
                overflowY: "scroll",
              }}
            >
              <div className="row h-100">
                <div
                  className="col-lg-8 pb-2 h-100"
                  style={{
                    overflowY: "hidden",
                    // position: "relative",
                  }}
                >
                  <WatchlistTab
                    watchlistDetails={watchlistDetails}
                    handleUpdater={handleUpdater}
                    shortBig="big"
                    selectedTab={selectedTab}
                  />
                </div>
                <div
                  className="col-lg-4 h-100"
                  style={{
                    overflowY: "scroll",
                    textAlign: "left",
                  }}
                >
                  <WatchlistOverviewer
                    watchlistToAnalyse={
                      watchlistDetails.filter(
                        (val) => selectedTab === val.id
                      )[0] || {}
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MyWatchlists;
