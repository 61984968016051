import React, { useEffect, useState } from "react";
import derivativesStore from "./derivatives-store";
import { formatDate } from "../../../utils/configs";
import { commonApiGet } from "../../../utils/api";
import { notificationTopup } from "../../../utils/NotificationTopup";
import OIVisualizerChart from "./chart/OIVisualizerChart";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const OIVisualizer = () => {
  const [updatedStore, setUpdatedStore] = useState(derivativesStore.getState());
  const [checkedItems, setCheckedItems] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [oiData, setOiData] = useState([]);

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to midnight
  const threeMonthsLater = new Date(today);
  threeMonthsLater.setMonth(today.getMonth() + 3); // Add 3 months
  threeMonthsLater.setHours(0, 0, 0, 0); // Set time to midnight

  useEffect(() => {
    const unsubscribe = derivativesStore.subscribe(() => {
      setUpdatedStore(derivativesStore.getState());
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    setCheckedItems([
      formatDate(new Date(updatedStore.selectedExpiry), "DD-MMM-YY"),
    ]);
  }, [updatedStore.selectedExpiry]);

  const handleCheckboxChange = (item) => {
    setCheckedItems((prev) => {
      if (prev.includes(item)) {
        return prev.filter((checkedItem) => checkedItem !== item);
      } else {
        return [...prev, item];
      }
    });
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    const filteredItems = (updatedStore.selectedOptionExpiryDates || [])
      .filter((dateStr) => {
        const date = new Date(dateStr);
        date.setHours(0, 0, 0, 0); // Set time to midnight for comparison
        return date >= today && date <= threeMonthsLater;
      })
      .map((item) => formatDate(new Date(item), "DD-MMM-YY"));

    if (isChecked) {
      setCheckedItems(filteredItems); // Select all
    } else {
      setCheckedItems([]); // Deselect all
    }
  };

  useEffect(() => {
    const fetchOptionChainData = () => {
      let expDt = formatDate(
        new Date(updatedStore.selectedOptionExpiryDates[0]),
        "DD-MMM-YY"
      );
      commonApiGet(
        `/indices/openinterest?symbol=${updatedStore.selectedIndex}&expiry=${expDt}`,
        "OIVISUALIZER",
        false
      )
        .then((res) => {
          setOiData(res.data.data);
        })
        .catch((err) => {
          notificationTopup(err.message);
        });
    };

    fetchOptionChainData(); // Call immediately on mount

    const intervalId = setInterval(() => {
      fetchOptionChainData(); // Call every 3 minutes
    }, 3 * 60 * 1000); // 3 minutes in milliseconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [updatedStore.selectedIndex]);

  return (
    <div className="container">
      <ButtonDropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle
          caret
          size="sm"
          color="transparent"
          className="general-nmphc-des text-color-themed mt-1 mx-2"
          style={{ fontSize: "12px" }}
        >
          {checkedItems.length > 0
            ? `Selected Expiries: ${checkedItems.length}`
            : "Select Items"}
        </DropdownToggle>
        <DropdownMenu
          className="general-nmphc-des non-trans-bg"
          style={{ maxHeight: "220px", overflowY: "scroll", zIndex: 1 }}
        >
          <DropdownItem className="bg-set-on-active text-color-themed">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                checked={
                  (updatedStore.selectedOptionExpiryDates || []).filter(
                    (dateStr) => {
                      const date = new Date(dateStr);
                      date.setHours(0, 0, 0, 0); // Set time to midnight for comparison
                      return date >= today && date <= threeMonthsLater;
                    }
                  ).length === checkedItems.length
                }
                onChange={handleSelectAllChange}
              />
              <label className="form-check-label">Select All</label>
            </div>
          </DropdownItem>
          {(updatedStore.selectedOptionExpiryDates || [])
            .filter((dateStr) => {
              const date = new Date(dateStr);
              date.setHours(0, 0, 0, 0); // Set time to midnight for comparison
              return date >= today && date <= threeMonthsLater;
            })
            .map((item) => formatDate(new Date(item), "DD-MMM-YY"))
            .map((item, index) => (
              <DropdownItem
                key={index}
                className="bg-set-on-active text-color-themed"
              >
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={checkedItems.includes(item)}
                    onChange={() => handleCheckboxChange(item)}
                  />
                  <label className="form-check-label">{item}</label>
                </div>
              </DropdownItem>
            ))}
        </DropdownMenu>
      </ButtonDropdown>

      <OIVisualizerChart
        data={oiData.filter((val) => checkedItems.includes(val.expiryDate))}
        underlyingValue={
          updatedStore.currData?.underlying?.underlyingValue || 23568
        }
        lengthOfExps={checkedItems.length}
      />
    </div>
  );
};

export default OIVisualizer;
