import { themedColor } from "../../../../utils/configs";
import { groupINRCurrencyNSE } from "../../../../utils/functions";

export const getChartOptions = (
  essentials,
  strikePrices,
  initialserieses,
  chartTitle,
  isZoom = true,
  extraConfs = {},
  theme
) => {
  return {
    title: {
      text: chartTitle,
      textStyle: {
        color: themedColor[theme],
      },
      left: "center",
      show: false,
    },

    grid: {
      left: "0%",
      right: "2%",
      bottom: "20%",
      containLabel: true,
    },
    tooltip: {
      trigger: "axis",
      className: "customEchartohlcTooltip glassmorfy-it general-nmphc-des",
      position: function () {
        const obj = { top: 10, left: 10 };
        return obj;
      },
      borderWidth: 1,
      borderColor: "#ccc",
      padding: 10,
      textStyle: {
        color: themedColor[theme],
        fontSize: "10",
      },
    },
    legend: { show: false },
    xAxis: {
      type: "category",
      data: strikePrices,
      name: "",
      splitLine: {
        show: false, // Disable X-axis grid lines
      },
      nameTextStyle: {
        fontWeight: "bold",
        fontSize: 12,
      },
      axisLabel: {
        color: themedColor[theme],
        fontSize: 12,
      },
    },
    yAxis: {
      type: "value",
      name: "",
      axisLabel: {
        formatter: (value) => {
          // Example: Format as currency with commas
          return groupINRCurrencyNSE(value);
        },
        color: themedColor[theme],
        fontSize: 12,
      },

      nameTextStyle: {
        fontWeight: "bold",
        fontSize: 12,
      },
      splitLine: {
        show: false, // Disable Y-axis grid lines
      },
    },
    series: [
      ...initialserieses,
      {
        name: "Underlying Value",
        type: "line",
        data: Array(strikePrices.length).fill(null), // Fill with nulls
        markLine: {
          symbol: "none", // Remove the marker
          data: [
            {
              xAxis: strikePrices.indexOf(essentials.nearestStrikePrice), // Position of the vertical line
              lineStyle: {
                type: "dashed", // Dotted line style
                color: themedColor[theme], // Color of the line
              },
              label: {
                show: true, // Show the label
                formatter: `Underlying Value: ${essentials.underlyingValue}`, // Customize the label text
                position: "end", // Position of the label
                color: themedColor[theme], // Color of the label
              },
            },
          ],
        },
      },
    ],
    ...(isZoom
      ? {
          dataZoom: [
            {
              type: "slider",
              xAxisIndex: [0],
              start: essentials.zoomStart, // Starting percentage of zoom
              end: essentials.zoomEnd, // Ending percentage of zoom
            },
            {
              type: "inside",
              xAxisIndex: [0],
              start: essentials.zoomStart,
              end: essentials.zoomEnd,
            },
          ],
        }
      : {}),
    ...extraConfs,
  };
};