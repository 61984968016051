import { configureStore, createSlice } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import { lastMarketOpenTime } from "../../../utils/configs";

// Create a slice for the store
const storeSlice = createSlice({
  name: "store",
  initialState: {
    currData: undefined,
    selectedIndex: "NIFTY",
    selectedExpiry: "date",
    optionLegs: [],
    selectedOptionExpiryDates: [],
  },
  reducers: {
    updateCurrData(state, action) {
      state.currData = action.payload;
    },
    updateSelectedIndex(state, action) {
      state.selectedIndex = action.payload;
    },
    updateSelectedExpiry(state, action) {
      state.selectedExpiry = action.payload;
    },
    setSBOptionLegs(state, action) {
      const { type, optionLeg, timeAdded } = action.payload;
      if (type === "add") {
        let isThisActionExecuted = state.optionLegs.findIndex(
          (opl) =>
            opl.optionType == optionLeg.optionType &&
            opl.strikePrice == optionLeg.strikePrice &&
            opl.action == optionLeg.action &&
            opl.expiryDate == optionLeg.expiryDate
        );
        let isThisExecuted = state.optionLegs.findIndex(
          (opl) =>
            opl.optionType == optionLeg.optionType &&
            opl.strikePrice == optionLeg.strikePrice &&
            opl.action != optionLeg.action &&
            opl.expiryDate == optionLeg.expiryDate
        );
        if (isThisExecuted === -1 && isThisActionExecuted !== -1) {
          state.optionLegs[isThisActionExecuted].lots =
            parseInt(state.optionLegs[isThisActionExecuted].lots) +
            parseInt(optionLeg.lots);
        } else if (isThisActionExecuted === -1 && isThisExecuted !== -1) {
          let newLot =
            parseInt(state.optionLegs[isThisExecuted].lots) -
            (optionLeg.lots || 1);
          if (newLot > 0) {
            state.optionLegs[isThisExecuted].lots = newLot;
          } else if (newLot < 0) {
            state.optionLegs[isThisExecuted].lots = Math.abs(newLot);
            state.optionLegs[isThisExecuted].action =
              state.optionLegs[isThisExecuted].action == "B" ? "S" : "B";
          } else {
            state.optionLegs.splice(isThisExecuted, 1);
          }
        } else {
          state.optionLegs.push({
            ...optionLeg,
            timeAdded,
            lots: optionLeg.lots || 1,
          });
        }
      } else if (type === "replace") {
        const index = action.payload.optionLegIndex;
        state.optionLegs[index] = optionLeg;
      } else if (type === "delete") {
        const index = action.payload.optionLegIndex;
        state.optionLegs.splice(index, 1);
      }
    },
    updateSelectedOptionExpiryDates(state, action) {
      state.selectedOptionExpiryDates = action.payload;
    },
    clearOptionsLeg(state, action) {
      state.optionLegs = [];
    },
  },
});

// Export actions
export const {
  updateCurrData,
  updateSelectedIndex,
  updateSelectedExpiry,
  setSBOptionLegs,
  updateSelectedOptionExpiryDates,
  clearOptionsLeg,
} = storeSlice.actions;

// Thunk action to add option legs
export const addOptionLeg = (optionLeg) => async (dispatch) => {
  const timeAdded = await lastMarketOpenTime();
  dispatch(
    setSBOptionLegs({
      type: "add",
      optionLeg,
      timeAdded: timeAdded.toISOString(),
    })
  );
};

// Create store
const derivativesStore = configureStore({
  reducer: storeSlice.reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export default derivativesStore;
