import axios from "axios";
import { nseBase } from "./configs";
import sha1 from "crypto-js/sha1";
import { hawkReport, hawkReportTypeIdentifiers } from "./hawkeye/hawkHelper";
export const backURL = nseBase;

import hawkQuiver from "./hawkeye/hawkQuiver";
import { appendJourney } from "./hawkeye/hawkQuiver";

export const errorMsg = "Contact Server Admin || Something unexpected happen";

const CACHE_TTL = 5 * 60 * 1000;
export const CACHE_PREFIX = "cache://api.qf-";
const isToCache = true;
const notToCache = [];
const req = axios.create({
  baseURL: backURL,
});

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + 5.5 * 60 * 60 * 1000 + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(c_name) {
  let c_start, c_end;
  if (document.cookie.length > 0) {
    c_start = document.cookie.indexOf(c_name + "=");
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1;
      c_end = document.cookie.indexOf(";", c_start);
      if (c_end == -1) {
        c_end = document.cookie.length;
      }
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return "";
}

req.interceptors.request.use(async (config) => {
  const csrftoken = getCookie("csrftoken");
  const accesstoken = getCookie("access_token");

  if (accesstoken) {
    config.headers.Authorization = `Bearer ${accesstoken}`;
  }
  if (csrftoken) {
    config.headers["X-CSRFToken"] = csrftoken;
  }

  config.headers["Content-Type"] = "application/json"; // Use hyphen
  // config.headers["Cache-Control"] = 'no-cache'; // Use hyphen
  // config.headers["Cache-Control"] = 'max-age=60'; // Use hyphen

  return config;
});

// Function to generate SHA256 hash
const getSha = (url) => `${CACHE_PREFIX}${sha1(`${CACHE_PREFIX}${url}`)}`;

// Local cache checker
const isCached = (url) => {
  if (notToCache.includes(url.split("?")[0])) return false;
  if (isToCache === false) return false;
  const cacheKey = getSha(url);
  const cachedData = sessionStorage.getItem(cacheKey);
  if (!cachedData) return false;

  const { gotAt } = JSON.parse(cachedData);
  const currentTime = new Date().getTime();
  const dataReceivedAt = new Date(gotAt).getTime();

  return currentTime - dataReceivedAt <= CACHE_TTL;
};

// Get cached data
const getCachedData = (url) => {
  const cacheKey = getSha(url);
  hawkQuiver.dispatch(
    appendJourney(`${new Date().getTime()}_QF-CACHE_${cacheKey}: ${url}`)
  );
  const cachedData = sessionStorage.getItem(cacheKey);
  if (!cachedData) return null;

  const { data } = JSON.parse(cachedData);
  return JSON.parse(data);
};

// Set cached data
const setCachedData = async (url, data) => {
  const cacheKey = getSha(url);

  const compressedData = JSON.stringify(data);

  sessionStorage.setItem(
    cacheKey,
    JSON.stringify({
      gotAt: new Date(),
      ttl: CACHE_TTL,
      data: compressedData,
    })
  );
};

// Common API GET
export const commonApiGet = async (
  url,
  invoker = "COMP-NOT-GIVEN",
  toCache = true,
  inbulitReplacer = true
) => {
  if (toCache) {
    if (isCached(url)) return getCachedData(url);
  }

  hawkQuiver.dispatch(
    appendJourney(
      `${new Date().getTime()}_QF-APICALL_${invoker}_commonApiGet: ${url}`
    )
  );
  return await req
    .get(inbulitReplacer ? url.replace("%", "%25") : url)
    .then((response) => {
      if (toCache) {
        setCachedData(url, response);
      }
      return response;
    })
    .catch(async (err) => {
      await postApiReqHandlation(err, {
        url,
        invoker,
        toCache,
      });
    });
};

// Common API POST
export const commonApiPostRB = async (url, data, toCache = true) => {
  const cookieKey = `${url}:::data:::${JSON.stringify(data)}`;
  if (toCache) {
    if (isCached(cookieKey)) return getCachedData(cookieKey);
  }

  console.log(
    `QF-JOURNAL-${new Date().getTime()}_QF-APICALL: commonApiPostRB_${url}`
  );
  return await req
    .post(url, data, {
      headers: {
        "content-type": "application/json",
      },
    })
    .then((response) => {
      if (toCache) {
        setCachedData(cookieKey, response);
      }
      return response;
    })
    .catch(async (err) => {
      await postApiReqHandlation(err);
    });
};

export const commonApiPost = async (url, data) => {
  console.log(
    `QF-JOURNAL-${new Date().getTime()}_QF-APICALL: commonApiPost_${url}`
  );
  return await req
    .post(url, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response;
    })
    .catch(async (err) => {
      await postApiReqHandlation(err);
    });
};

export const commonApiPut = async (url, data) => {
  console.log(
    `QF-JOURNAL-${new Date().getTime()}_QF-APICALL: commonApiPut_${url}`
  );
  return await req
    .put(url, data)
    .then((response) => {
      return response;
    })
    .catch(async (err) => {
      await postApiReqHandlation(err);
    });
};

export const commonApiDelete = async (url, data = null) => {
  const token = localStorage.getItem("token");
  console.log(
    `QF-JOURNAL-${new Date().getTime()}_QF-APICALL: commonApiDelete_${url}`
  );
  if (data === null) {
    return await req
      .delete(url)
      .then((response) => {
        return response;
      })
      .catch(async (err) => {
        await postApiReqHandlation(err);
      });
  } else {
    var config = {
      method: "delete",
      url: backURL + url,
      headers: {
        Authorization: token ? token : "",
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(config)
      .then((response) => {
        return response;
      })
      .catch(async (err) => {
        await postApiReqHandlation(err);
      });
  }
};

export const fileUploadApi = async (url, data) => {
  console.log(
    `QF-JOURNAL-${new Date().getTime()}_QF-APICALL: fileUploadApi_${url}`
  );

  return await req
    .post(url, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response;
    })
    .catch(async (err) => {
      await postApiReqHandlation(err);
    });
};

export const removeCookieUserSession = () => {
  document.cookie.split(";").forEach((cookie) => {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie =
      name +
      "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.quibblefrost.in";
  });
};

export const removeUserSession = (to = "") => {
  removeCookieUserSession();
  // location.replace(backURL + `/auth/signout`);
  location.replace(`/${to}`);
};

const postApiReqHandlation = async (err, additionalData) => {
  console.error("QF-JOURNAL-ERROR-API-CALL", err);
  hawkReport(err, additionalData, hawkReportTypeIdentifiers.apiError);
  const response = err.response;
  if (
    response?.status === 401 ||
    response?.status === 602 ||
    response?.status === 603 ||
    response?.status === 604
  ) {
    removeUserSession(`signin?callback=${location.pathname}`);
    throw err;
  } else {
    throw err;
  }
};
