import React, { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";
import { useSelector } from "react-redux";
import Divider from "../../../../views/preLogin/components/Divider";
import { getChartOptions } from "./OIChartConfig";
import { chartConfigs } from "./OIChartPrep";
import { dataPrep } from "./OIChartDataPrep";

const OIVisualizerChart = ({ data, underlyingValue, lengthOfExps }) => {
  const theme = useSelector((state) => state.theme);
  const [zoomStart, setZoomStart] = useState(0);
  const [zoomEnd, setZoomEnd] = useState(100);

  // Prepare the data for the charts
  const strikePrices = [...new Set(data.map((item) => item.strikePrice))].sort(
    (a, b) => a - b
  );

  // Find the nearest strike price to the underlying value
  const nearestStrikePrice =
    strikePrices.length > 0
      ? strikePrices.reduce((prev, curr) => {
          return Math.abs(curr - underlyingValue) <
            Math.abs(prev - underlyingValue)
            ? curr
            : prev;
        })
      : null; // Set to null if no strike prices are available

  // Find the index of the nearest strike price
  const defaultIndex =
    nearestStrikePrice !== null
      ? strikePrices.findIndex((price) => price === nearestStrikePrice)
      : -1; // Set to -1 if no nearest strike price

  // Calculate the start and end for the zoom based on the default index
  useEffect(() => {
    if (defaultIndex !== -1) {
      const newZoomStart =
        Math.max(0, defaultIndex - 5) * (100 / strikePrices.length); // Zoom in 5 strikes before
      const newZoomEnd =
        Math.min(100, defaultIndex + 5) * (100 / strikePrices.length); // Zoom in 5 strikes after
      setZoomStart(newZoomStart);
      setZoomEnd(newZoomEnd);
    }
  }, [defaultIndex, strikePrices.length]);

  const handleChartReady = (echartsInstance) => {
    echartsInstance.dispatchAction({
      type: "dataZoom",
      start: zoomStart,
      end: zoomEnd,
    });
  };

  console.log(
    zoomStart,
    zoomEnd,
    nearestStrikePrice,
    defaultIndex,
    strikePrices
  );
  const renderCharts = () => {
    return chartConfigs({
      theme,
      ...dataPrep(data, lengthOfExps, strikePrices),
    }).map((config, index) => (
      <div key={index}>
        <Divider role="text" text={config.title} py={1} />
        <ReactECharts
          className="mx-auto py-3"
          option={getChartOptions(
            { nearestStrikePrice, underlyingValue, zoomStart, zoomEnd },
            strikePrices,
            config.initialserieses,
            config.title,
            config.isZoom,
            config.extraConfs || {},
            theme
          )}
          style={{ height: "350px", width: "100%" }}
          opts={{ renderer: "canvas" }}
          onChartReady={handleChartReady} // Use the onChartReady prop
        />
        <br />
      </div>
    ));
  };

  // Check if strikePrices is empty
  if (strikePrices.length === 0) {
    return <div>No data available to display.</div>; // Handle empty case
  }

  return (
    <div
      style={{
        maxHeight: "69vh",
        overflowY: "scroll",
      }}
    >
      {renderCharts()}
    </div>
  );
};

export default OIVisualizerChart;
